<template>
  <div
    style="
      background: rgba(240, 240, 240, 1);
      border-radius: 6px;
      padding: 10px;
    "
  >
    <div v-for="(child, index1) in treeData" :key="index1">
      <p class="user-info" style="margin-bottom: 0">
        <personal-avatar
          :avatar="matchUserInfo(child.createdBy).avatar"
          :colorid="matchUserInfo(child.createdBy).accountId"
          :nickname="matchUserInfo(child.createdBy).nickname"
          :size="30"
          :tool-tip-disabled="true"
          style="margin-right: 5px; display: inline-block"
        ></personal-avatar>
        <span
          >{{ matchUserInfo(child.createdBy).nickname
          }}{{ $t("knowledge.Reply")
          }}{{ matchUserInfo(child.to).nickname }}</span
        >
      </p>
      <p
        class="comment_list_html"
        style="transform: scale(0.7); transform-origin: 0 0; margin: 0"
      >
        <span>{{
          new Date(child.createdAt).Format("yyyy-MM-dd hh:mm:ss")
        }}</span>
      </p>
      <p class="comment_list_html" v-html="child.html"></p>
      <p class="comment_list_opt">
        <i
          v-if="userAccountId === child.createdBy"
          class="iconfont icon-delete-red"
          style="font-size: 20px; margin-right: 20px; cursor: pointer"
          @click="delFn(child)"
        />
        <i
          v-if="child.likedList.indexOf(userAccountId) !== -1"
          class="iconfont icon-k_zan"
          style="font-size: 20px; cursor: pointer; color: rgb(255, 195, 0)"
          @click.stop="zanFn(child)"
        />
        <i
          v-else
          class="iconfont icon-a-31dianzan"
          style="font-size: 20px; cursor: pointer"
          @click.stop="zanFn(child)"
        />
        <span style="margin-right: 20px"> {{ child.likedList.length }}</span>
        <i
          class="iconfont icon-comment"
          style="font-size: 18px; cursor: pointer"
          @click="repeatFn(child)"
        />
      </p>
      <rightCommnetQuill
        v-if="child.showRepeat"
        :info="child"
        :checked-node="checkedNode"
        :parent-comment-id="child.commentId"
        :repeat="true"
        v-on="$listeners"
      ></rightCommnetQuill>
      <tree-comment
        v-if="child.children"
        :checked-node="checkedNode"
        :tree-data="child.children"
        v-on="$listeners"
      ></tree-comment>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PersonalAvatar from "@/components/personal";
import rightCommnetQuill from "./rightCommnetQuill.vue";
import { delComment, like, unlike } from "@/network/knowledge";
export default {
  name: "TreeComment",
  components: {
    PersonalAvatar,
    rightCommnetQuill,
  },
  props: {
    treeData: {
      type: Array,
      default() {
        return [];
      },
    },
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userAccountId", "user_list"]),
    myself() {
      return this.matchUserInfo(this.userAccountId);
    },
  },
  methods: {
    repeatFn(item) {
      this.$emit("hideOthersReapet");
      item.showRepeat = true;
    },
    zanFn(item) {
      const params = {
        projectId: this.get_pid(),
        documentId: item.documentId,
        commentId: item.commentId,
      };
      if (item.likedList.indexOf(this.userAccountId) !== -1) {
        unlike(params).then((res) => {
          item.likedList.splice(item.likedList.indexOf(this.userAccountId), 1);
        });
      } else {
        like(params).then((res) => {
          item.likedList.push(this.userAccountId);
        });
      }
    },
    delFn(item) {
      const params = {
        projectId: this.get_pid(),
        documentId: item.documentId,
        commentId: item.commentId,
      };
      delComment(params).then((res) => {
        this.$emit("getComments");
      });
    },
  },
};
</script>

<style scoped lang="scss">
.user-info {
  display: flex;
  align-items: center;
  & > span {
    margin: 0 0 0 0;
    /*no*/
  }
}
.comment_list_html {
  margin-left: calc(30px + 0.026rem) !important;
  word-break: break-all;
  /*no*/
}
.comment_list_opt {
  text-align: right;
  margin: 10px 0 0 30px;
}
</style>
