<template>
  <div class="main-wrap">
    <div class="topBox">
      <Title
        :checked-node="checkedNode"
        :title-mind-data="titleMindData"
        :status-list="statusList"
        v-on="$listeners"
      ></Title>
      <Tool
        v-if="checkedNode"
        ref="tool"
        :catalogue-data="catalogueData"
        :checked-node="checkedNode"
        :auth-list="authList"
        :title-mind-data="titleMindData"
        :draggable="draggable"
        :html="html"
        @updateMindData="updateMindData"
        v-on="$listeners"
      ></Tool>
    </div>
    <div id="not-edit-wrap" class="not-edit-wrap">
      <Catalogue
        :checked-node="checkedNode"
        @catalogueUpdate="catalogueUpdate"
      ></Catalogue>
      <Document
        ref="document"
        :checked-node="checkedNode"
        :right-comment-list="rightCommentList"
        :status-list="statusList"
        @titleMindChange="titleMindChange"
        @getComments="getComments"
        @filterRight="filterRight"
        @exportHtml="exportHtml"
      ></Document>
      <right-comment
        ref="rightComment"
        :checked-node="checkedNode"
        @commentinline="commentinline"
      ></right-comment>
    </div>
    <div class="goTop">
      <i class="iconfont icon-a-huaban18" @click="goTop"></i>
    </div>
  </div>
</template>

<script>
import Title from "./notEdit/title.vue";
import Tool from "./notEdit/tool.vue";
import Catalogue from "./catalogue.vue";
import Document from "./notEdit/document.vue";
import rightComment from "./notEdit/rightComment.vue";
import { get_allreadyuse_status_list } from "@/network/workflow/index.js";
export default {
  components: {
    Title,
    Tool,
    Catalogue,
    Document,
    rightComment,
  },
  props: {
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
    authList: {
      type: Array,
      default() {
        return [];
      },
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    // rightCommentList: {
    //   type: Array,
    //   default() {
    //     return [];
    //   },
    // },
  },
  data() {
    return {
      editor: null,
      titleMindData: [],
      html: "",
      catalogueData: [],
      statusList: [],
      rightCommentList: [],
    };
  },
  mounted() {
    // 重复调用了
    // this.updateMindData();
    this.getStatusList();
    this.$watch(
      () => {
        return this.$refs.tool.authorityForm.interView;
      },
      (val) => {
        this.$refs.document.isPublic = val;
      }
    );
    const goTop = document.getElementsByClassName("goTop")[0];
    goTop.style.display = "none";
  },
  methods: {
    async getStatusList() {
      this.statusList = await get_allreadyuse_status_list(this.get_pid());
    },
    // 传递导出数据
    exportHtml(html) {
      this.html = html;
    },
    // 定位某行
    positionLine(lineId) {},
    // filterRight(id) {
    //   this.$emit("filterRight", id);
    // },
    filterRight(id) {
      this.$refs.rightComment.filterRight(id);
    },
    getComments() {
      this.$refs.rightComment.getComments();
    },
    catalogueUpdate(params) {
      this.catalogueData = params;
    },
    updateMindData() {
      this.$refs.document.getMindData();
    },
    titleMindChange(params) {
      this.titleMindData = params;
    },
    commentinline(list) {
      this.rightCommentList = list;
    },
    goTop() {
      const doc = document.getElementById("no-hidden-dialog");
      if (doc) {
        doc.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main-wrap {
  width: 65%;
  height: 100%;
  padding-top: 27px;
  // padding-left: 48px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-grow: 1;
  .not-edit-wrap {
    display: flex;
    justify-content: flex-start;
    height: calc(100% - 400px);
    // padding-top: 50px;
    padding-bottom: 10px;
    flex: 1 1 auto;
  }
}
.topBox {
  padding-bottom: 2px;
  padding-right: 20px;
  border-bottom: 1px solid rgba(221, 223, 230, 1);
  display: flex;
  justify-content: space-between;
}
.goTop {
  position: absolute;
  bottom: 50px;
  right: 30px;
  border-radius: 50%;
  background-color: white;
  i {
    cursor: pointer;
    font-size: 30px;
    font-weight: 600;
    color: rgba(145, 147, 153, 1);
  }
}
</style>
