<template>
  <div class="loading_wrap">
    <div>
      <div>
        <i
          class="iconfont icon-clocks_blue"
          style="font-size: 90px; float: left; color: rgb(48, 100, 143)"
        ></i>
      </div>
      <div>
        <p>{{ $t("knowledge.Loading") }}</p>
        <p>{{ $t("knowledge.EnteringKnowledgeBase") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.loading_wrap {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    display: flex;
    & > div {
      &:nth-of-type(1) {
        margin-right: 48px;
      }
      & > p {
        margin: 0;
        text-align: left;
        &:nth-of-type(1) {
          font-size: 34px;
        }
        &:nth-of-type(2) {
          font-size: 28px;
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
