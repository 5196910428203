<template>
  <div class="bottom-comment-wrap">
    <p class="documnet-end-word">
      <i
        class="iconfont icon-shijian"
        style="font-size: 27px; margin-right: 10px"
      />
      <span style="margin-right: 20px"
        >{{ $t("knowledge.Creator") }}:
        {{ matchUserNickname(createdBy, "") }}
      </span>
      <span class="end-time"
        >{{ matchUserNickname(updatedBy, "") }}{{ $t("knowledge.LastEditedTime") }}
        {{ new Date(updatedAt).Format("yyyy-MM-dd hh:mm:ss") }}</span
      >
      <i
        class="iconfont icon-comment"
        style="margin-right: 10px; font-size: 24px"
      />
      <span>{{ commentLength }}</span>
    </p>
    <div class="end-line">
      <span></span>
      <span>end</span>
      <span></span>
    </div>
    <p class="comment-title">{{ $t("knowledge.CommentSection") }}</p>
    <div class="input-wrap">
      <p class="user-info">
        <personal-avatar
          :avatar="myself.avatar"
          :colorid="myself.accountId"
          :nickname="myself.nickname"
          :size="30"
          :tool-tip-disabled="true"
          style="margin-right: 5px; display: inline-block"
        ></personal-avatar>
        <span>{{ myself.nickname }}</span>
      </p>
      <comment-quill
        :checked-node="checkedNode"
        @getComments="getComments"
        @hideOthersReapet="hideOthersReapet"
      ></comment-quill>
    </div>
    <div class="bottom-line"></div>
    <div id="comment-wrap" class="comment-wrap">
      <div v-if="commentList.length">
        <div
          v-for="(item, index) in commentList"
          :id="item.commentId"
          :key="index"
          class="comment_line"
        >
          <p class="user-info">
            <personal-avatar
              :avatar="matchUserInfo(item.createdBy).avatar"
              :colorid="matchUserInfo(item.createdBy).accountId"
              :nickname="matchUserInfo(item.createdBy).nickname"
              :size="30"
              :tool-tip-disabled="true"
              style="margin-right: 5px; display: inline-block"
            ></personal-avatar>
            <span>{{ matchUserInfo(item.createdBy).nickname }}</span>
            <span>{{
              new Date(item.createdAt).Format("yyyy-MM-dd hh:mm:ss")
            }}</span>
          </p>
          <p class="comment_list_html" v-html="item.html"></p>
          <p class="comment_list_opt">
            <i
              v-if="item.likedList.indexOf(userAccountId) !== -1"
              class="iconfont icon-k_zan"
              style="cursor: pointer; color: rgb(255, 195, 0); font-size: 20px"
              @click="zanFn(item)"
            />
            <i
              v-else
              class="iconfont icon-a-31dianzan"
              style="font-size: 20px; cursor: pointer"
              @click="zanFn(item)"
            />
            <span style="margin-right: 20px"> {{ item.likedList.length }}</span>
            <i
              class="iconfont icon-comment"
              style="font-size: 20px; margin-right: 40px; cursor: pointer"
              @click="repeatFn(item)"
            />
          </p>
          <comment-quill
            v-if="item.showRepeat"
            :checked-node="checkedNode"
            :parent-comment-id="item.commentId"
            :info="item"
            :repeat="true"
            @getComments="getComments"
            @hideOthersReapet="hideOthersReapet"
          ></comment-quill>
          <treeComment
            v-if="item.children"
            :checked-node="checkedNode"
            :tree-data="item.children"
            @getComments="getComments"
            @hideOthersReapet="hideOthersReapet"
          ></treeComment>
        </div>
      </div>
      <div v-else class="no_comment">
        <i class="iconfont icon-a-no_comment1" style="font-size: 90px" />
        {{ $t("knowledge.NoComment") }}
      </div>
    </div>
    <div id="hide-wrap-bottom" class="hide-wrap-bottom"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PersonalAvatar from "@/components/personal";
import treeComment from "./treeComment.vue";
import commentQuill from "./commentQuill.vue";
import { getComments, like, unlike } from "@/network/knowledge";
import Quill from "quill";
import QuillBetterTable from "@/plugins/quill-better-table.js";
export default {
  components: {
    PersonalAvatar,
    treeComment,
    commentQuill,
  },
  props: {
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
    updatedAt: {
      type: String,
      default() {
        return "";
      },
    },
    updatedBy: {
      type: String,
      default() {
        return "";
      },
    },
    createdBy: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      quill: null,
      commentList: [],
      commentLength: 0,
      formatObject: {},
    };
  },
  computed: {
    ...mapGetters(["userAccountId", "user_list"]),
    myself() {
      return this.matchUserInfo(this.userAccountId);
    },
  },
  watch: {
    checkedNode(newval) {
      if (newval) {
        this.getComments();
      }
    },
  },
  mounted() {
    this.initQuill();
    this.getComments();
  },
  methods: {
    initQuill() {
      this.quill = new Quill("#hide-wrap-bottom", {
        modules: {
          toolbar: false,
          history: {
            delay: 2000,
            maxStack: 500,
          },
          ImageResize: {
            modules: ["Resize", "DisplaySize"],
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
          table: false, // disable table module
          "better-table": {
            operationMenu: {
              items: {
                insertColumnRight: {
                  text: this.$t("knowledge.InsertRight"),
                },
                insertColumnLeft: {
                  text: this.$t("knowledge.InsertLeft"),
                },
                insertRowUp: {
                  text: this.$t("knowledge.InsertAbove"),
                },
                insertRowDown: {
                  text: this.$t("knowledge.InsertBelow"),
                },
                mergeCells: {
                  text: this.$t("knowledge.MergeCells"),
                },
                unmergeCells: {
                  text: this.$t("knowledge.SplitCells"),
                },
                deleteColumn: {
                  text: this.$t("knowledge.DeleteColumn"),
                },
                deleteRow: {
                  text: this.$t("knowledge.DeleteRow"),
                },
                deleteTable: {
                  text: this.$t("knowledge.DeleteTable"),
                },
              },
            },
          },
          keyboard: {
            bindings: QuillBetterTable.keyboardBindings,
          },
        },
        theme: "snow",
      });
    },
    // 隐藏其他评论
    hideOthers(data) {
      data.forEach((item) => {
        item.showRepeat = false;
        item.children ? this.hideOthers(item.children) : "";
      });
    },
    hideOthersReapet() {
      this.hideOthers(this.commentList);
    },
    buildTreeFn(data, id, createdBy) {
      const self = this;
      const arr = [];
      data.forEach((item) => {
        if (item.parentId === id) {
          item.children = this.buildTreeFn(
            data,
            item.commentId,
            item.createdBy
          );
          const delta = JSON.parse(item.content);
          self.quill.setContents(delta);
          item.html = self.quill.root.innerHTML;
          item.showRepeat = false;
          createdBy ? (item.to = createdBy) : "";
          arr.push(item);
        }
      });
      return arr;
    },
    getComments() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
        query: {
          type: "document",
        },
      };
      getComments(params).then((res) => {
        this.commentLength = res.length;
        const buildTree = this.buildTreeFn(res, "");
        this.commentList = buildTree;
        this.$nextTick(() => {
          const flag = res.some((item) => {
            return item.commentId === this.$route.query.commentId;
          });
          flag ? this.locationElement() : "";
        });
      });
    },
    // 定位参数dom
    locationElement() {
      if (this.$route.query && this.$route.query.commentId) {
        window.location.href = "#" + this.$route.query.commentId;
      }
    },
    repeatFn(item) {
      this.hideOthersReapet();
      item.showRepeat = true;
    },
    zanFn(item) {
      const params = {
        projectId: this.get_pid(),
        documentId: item.documentId,
        commentId: item.commentId,
      };
      if (item.likedList.indexOf(this.userAccountId) !== -1) {
        unlike(params).then((res) => {
          item.likedList.splice(item.likedList.indexOf(this.userAccountId), 1);
        });
      } else {
        like(params).then((res) => {
          item.likedList.push(this.userAccountId);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.user-info {
  color: rgba(166, 166, 166, 1);
  display: flex;
  align-items: center;
  & > span {
    margin: 0 0 0 35px;
    /*no*/
  }
}
.bottom-comment-wrap {
  .documnet-end-word {
    color: rgba(128, 128, 128, 1);
    margin-top: 50px;
    display: flex;
    align-items: center;
    .end-time {
      margin-right: 30px;
    }
  }
  .end-line {
    margin: 55px 0 45px 0;
    color: rgba(166, 166, 166, 1);
    font-size: 20px;
    text-align: center;
    & > span {
      &:nth-of-type(odd) {
        display: inline-block;
        width: 128px;
        height: 1px;
        background-color: rgba(166, 166, 166, 1);
        vertical-align: middle;
      }
      &:nth-of-type(even) {
        margin: 0 34px;
      }
    }
  }
  .comment-title {
    color: rgba(56, 56, 56, 1);
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 50px;
  }
  .bottom-line {
    width: 100%;
    height: 1px;
    background-color: rgba(128, 128, 128, 1);
    margin: 40px 1% 40px 1%;
  }
  .comment-wrap {
    padding-bottom: 100px;
    .comment_line {
      margin-bottom: 50px;
      & > div {
        width: 90%;
        margin-left: 10%;
        padding: 0 20px 0 0;
      }
    }
    .comment_list_html {
      margin-left: 70px;
      /*no*/
    }
    .comment_list_opt {
      text-align: right;
      margin: 10px 0 0 30px;
    }
    .no_comment {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: rgba(128, 128, 128, 1);
    }
    .input-wrap {
      width: 96%;
      margin: 0 2%;
      border-radius: 6px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25),
        0px 2px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 30px;
    }
  }
}
</style>
<style lang="scss">
.ql-editor {
  min-height: 200px;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: #ebeef5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(207, 207, 207, 1);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(255, 255, 255, 1);
  }
}
.ql-font {
  margin-right: 10px;
}
.ql-font,
.ql-size {
  border: 1px solid rgba(204, 204, 204, 1);
  border-radius: 2px;
}
.ql-editing {
  left: 20px !important;
}
.ql-video {
  width: 1027px;
  height: 624px;
}
.hide-wrap-bottom {
  display: none;
}
</style>
