<template>
  <div class="title-bar">
    <h1 class="title">{{ titleText }}</h1>
    <div class="header">
      <div class="info-line">
        <p class="creator">{{ $t("knowledge.Creator") }}: {{ creator }}</p>
        <p class="created-at">
          {{ $t("knowledge.LatestVersion") }}: {{ formattedCreatedAt }}
        </p>
      </div>
      <div>
        <el-button class="return-button" @click="returnDocPage">
          {{ $t("knowledge.Exit") }}
        </el-button>
        <el-button class="title-button" type="primary" @click="recover">
          {{ $t("knowledge.RestoreThisPage") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    titleText: {
      type: String,
      required: true,
    },
    creator: {
      type: String,
      required: true,
    },
    updatedAt: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedCreatedAt() {
      const dt = new Date(this.updatedAt);
      const formattedYear = dt.getFullYear();
      const formattedMonth = ("0" + (dt.getMonth() + 1)).slice(-2); // format month to two digits
      const formattedDate = ("0" + dt.getDate()).slice(-2); // format date to two digits
      const formattedHours = ("0" + dt.getHours()).slice(-2); // format hours to two digits
      const formattedMinutes = ("0" + dt.getMinutes()).slice(-2); // format minutes to two digits
      // Combine all parts together
      return `${formattedYear}/${formattedMonth}/${formattedDate}  ${formattedHours}:${formattedMinutes}`;
    },
  },
  watch: {
    creator(newVal) {
      this.creator = newVal;
    },
  },
  methods: {
    recover() {
      this.$emit("recoverVersion", true);
      this.$router.push({
        path: `/${this.get_pid()}/knowledge?documentId=${
          this.$route.query.documentId
        }&title=${this.$route.query.title}`,
      });
    },
    returnDocPage() {
      this.$router.push({
        path: `/${this.get_pid()}/knowledge?documentId=${
          this.$route.query.documentId
        }&title=${this.$route.query.title}`,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.title-bar {
  height: 163px;
  border-bottom: 2.66px solid rgba(229, 229, 229, 1);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 1);
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1,
p {
  line-height: 0px;
  margin: 0px;
  padding: 0px;
}

.title {
  margin-left: 90px;
  margin-top: 21px;
  width: 556px;
  height: 61px;
  opacity: 1;
  /** 文本1 */
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 63.71px;
  color: rgba(56, 56, 56, 1);
  text-align: left;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.creator,
.created-at {
  height: 35px;
  opacity: 1;
  /** 文本1 */
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 34.75px;
  color: rgba(166, 166, 166, 1);
  text-align: left;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.creator {
  margin-left: 0;
}
.info-line {
  display: flex;
  justify-content: space-between;
  width: 564px;
  margin-left: 90px;
}
.title-button {
  margin-left: auto;
  margin-right: 85px;
  width: 110px;
  margin-bottom: 39px;
  height: 40px;
  opacity: 1;
  background: rgba(0, 76, 170, 1);
  border: 3px solid rgba(48, 100, 143, 1);
}
.return-button {
  margin-right: 10px;
  margin-left: auto;
  width: 110px;
  margin-bottom: 39px;
  height: 40px;
}
</style>
