<template>
  <div id="no-hidden-dialog" class="ql-snow no-hidden-dialog">
    <div class="ql-editor">
      <div id="document-wrap" class="document-wrap">
        <el-img-viewer
          v-if="isViewerVisible"
          class="viewer"
          :url-list="nowImgsrc"
          :on-close="closeImgViewer"
        ></el-img-viewer>
        <div id="document-content" @click="showImage">
          <div
            class="top-label"
            :class="{ hidden: this.selectedIndexes.length != 2 }"
          >
            <ins
              style="
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 2px;
              "
            >
              {{ $t("knowledge.AddedContent") }}</ins
            >
            <span style="visibility: hidden">--</span>
            <del
              style="
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 2px;
              "
            >
              {{ $t("knowledge.DeletedContent") }}</del
            >
          </div>
          <div v-html="dividedHtml"></div>
        </div>
        <div id="hide-wrap" class="hide-wrap"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getContent } from "@/network/knowledge";
import Quill from "quill";
import { distribution, sendComments } from "@/network/knowledge";
import QuillBetterTable from "@/plugins/quill-better-table.js";
import DOMPurify from "dompurify";
import hljs from "highlight.js";
import HtmlDiff from "htmldiff-js";
export default {
  components: {
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  props: {
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
    rightCommentList: {
      type: Array,
      default() {
        return [];
      },
    },
    documentId: {
      type: String,
      default: "",
    },
    selectedIndex: {
      type: Number,
      default: 0,
    },
    docHistories: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedIndexes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      quill: null,
      quill2: null,
      // 目前选中的行id
      lineId: "",
      data: "",
      data1: "",
      data2: "",
      outerWidth: 0,
      nowImgsrc: [],
      atShow: false,
      atShowposition: {
        left: 0,
        top: 0,
      },
      isViewerVisible: false,
      cache: {},
    };
  },
  computed: {
    dividedHtml() {
      // const dividedHtml = DOMPurify.sanitize(this.data);
      const dividedHtml = this.data;
      return dividedHtml;
    },
  },
  watch: {
    $route(val) {
      if (val.hash) {
        this.locationElement(val.hash);
      }
    },
    selectedIndex(newVal, oldVal) {
      if (this.docHistories && this.docHistories.length > 0) {
        this.gethtml(this.documentId);
      }
    },
    docHistories(newVal, oldVal) {
      if (newVal && newVal.length > 0) {
        this.gethtml(this.documentId);
      }
    },
    selectedIndexes(newVal, oldVal) {
      this.gethtml(this.documentId);
    },
  },
  mounted() {
    this.initQuill();
    // 监听dom变化
    this.listenerwidth();
    // this.gethtml(this.checkedNode ? this.checkedNode.documentId : "");
    if (this.docHistories && this.docHistories.length > 0) {
      this.gethtml(this.documentId);
    }
  },
  methods: {
    showImage(event) {
      if (event.target.localName === "img") {
        this.nowImgsrc = [event.target.currentSrc];
        this.isViewerVisible = true;
      }
      if (event.target.id.split("#")[0] === "link") {
        let l = event.target.attributes.link.value;
        console.log();

        window.open(
          (l.slice(0, 4) == "http" ? "" : "//") +
            event.target.attributes.link.value,
          "_blank"
        );
      }
    },
    closeImgViewer() {
      this.isViewerVisible = false;
    },
    // 监听宽度变化
    listenerwidth() {
      const self = this;
      this.observer = new ResizeObserver((entries) => {
        entries.forEach((item) => {
          self.outerWidth = item.borderBoxSize[0].inlineSize;
        });
      });
      let content = document.getElementById("document-content");
      this.observer.observe(content);
    },
    initQuill() {
      this.quill = new Quill("#hide-wrap", {
        modules: {
          toolbar: false,
          history: {
            delay: 2000,
            maxStack: 500,
          },
          ImageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
          table: false, // disable table module
          "better-table": {
            operationMenu: {
              items: {
                insertColumnRight: {
                  text: this.$t("knowledge.InsertRight"),
                },
                insertColumnLeft: {
                  text: this.$t("knowledge.InsertLeft"),
                },
                insertRowUp: {
                  text: this.$t("knowledge.InsertAbove"),
                },
                insertRowDown: {
                  text: this.$t("knowledge.InsertBelow"),
                },
                mergeCells: {
                  text: this.$t("knowledge.MergeCells"),
                },
                unmergeCells: {
                  text: this.$t("knowledge.SplitCells"),
                },
                deleteColumn: {
                  text: this.$t("knowledge.DeleteColumn"),
                },
                deleteRow: {
                  text: this.$t("knowledge.DeleteRow"),
                },
                deleteTable: {
                  text: this.$t("knowledge.DeleteTable"),
                },
              },
            },
          },
          keyboard: {
            bindings: QuillBetterTable.keyboardBindings,
          },
        },
        theme: "snow",
      });
    },
    getHtmlFromVersionAsync(version) {
      return new Promise((resolve) => {
        const delta = JSON.parse(version.content);
        this.quill.setContents(delta, "api");
        resolve(this.quill.root.innerHTML);
      });
    },
    getDiffHtml(content1, content2) {
      return HtmlDiff.execute(content1, content2);
    },

    gethtml(id) {
      const docContent = this.docHistories[this.selectedIndex];

      if (this.selectedIndexes.length === 2) {
        const docContent1 = this.docHistories[this.selectedIndexes[0]];
        const docContent2 = this.docHistories[this.selectedIndexes[1]];

        // Check cache before requesting
        let contentPromise1 = this.cache[docContent1.versionNumber]
          ? Promise.resolve(this.cache[docContent1.versionNumber])
          : this.getHtmlFromVersionAsync(docContent1);

        let contentPromise2 = this.cache[docContent2.versionNumber]
          ? Promise.resolve(this.cache[docContent2.versionNumber])
          : this.getHtmlFromVersionAsync(docContent2);

        Promise.all([contentPromise1, contentPromise2]).then(
          ([content1, content2]) => {
            // Store in cache if not present
            if (!this.cache[docContent1.versionNumber]) {
              this.cache[docContent1.versionNumber] = content1;
            }

            if (!this.cache[docContent2.versionNumber]) {
              this.cache[docContent2.versionNumber] = content2;
            }

            this.data =
              docContent1.versionNumber > docContent2.versionNumber
                ? this.getDiffHtml(content2, content1)
                : this.getDiffHtml(content1, content2);
          }
        );
      } else if (docContent.content) {
        // Check cache before requesting
        if (this.cache[docContent.versionNumber]) {
          this.data = this.cache[docContent.versionNumber];
        } else {
          this.getHtmlFromVersionAsync(docContent).then((content) => {
            // Store in cache
            this.cache[docContent.versionNumber] = content;
            this.data = content;
          });
        }
      } else {
        this.data = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
#document-content {
  min-height: 40vh;
}
.at_position {
  position: absolute;
}
#toolbar-container2 {
  display: none;
}
#editor-container2 {
  width: 100%;
  border-top: 1px solid #ccc;
}
.ql-snow,
.ql-edit {
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  .ql-editor {
    height: fit-content;
    padding-right: 0px;
  }
}
::v-deep .my-alink,
::v-deep .my-atitle,
::v-deep .my-adesc,
::v-deep .my-acard_link {
  cursor: pointer;
}
.document-wrap {
  width: 100%;
  padding-left: 84px;
  padding-right: 15px;
  padding-bottom: 40px;
  text-align: left;
  overflow-x: visible;
  position: relative;
  overflow-y: visible;
  .hide-wrap {
    display: none;
  }
  .toolbar-container {
    display: none;
  }
}
#menu {
  display: none;
  position: absolute;
  width: 130px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  div {
    div {
      height: 40px;
      line-height: 40px;
      color: rgba(48, 100, 143, 1);
      font-size: 12px;
      text-align: center;
      cursor: default;
      &:hover {
        background: rgba(229, 229, 229, 0.61);
        cursor: pointer;
      }
    }
  }
}
.top-label {
  margin-left: auto;
  text-align: right;
  font-size: 18px;
}
</style>
<style lang="scss">
.no-hidden-dialog {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: #ebeef5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(207, 207, 207, 1);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(255, 255, 255, 1);
  }
  .el-dialog {
    overflow: visible;
  }
}

.document-wrap {
  & > div {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding-right: 200px !important;
      /*no*/
    }
  }
}
ins {
  opacity: 1;
  background: rgba(42, 130, 228, 0.13);
  text-decoration: none;
  padding-top: 5px; /* Adjust these values as needed */
  padding-bottom: 5px;
  box-decoration-break: clone;
}

del {
  opacity: 1;
  background: rgba(128, 128, 128, 0.3);
  padding-top: 5px; /* Adjust these values as needed */
  padding-bottom: 5px;
  box-decoration-break: clone;
}
.hidden {
  visibility: hidden;
}
</style>
<style>
.position_dom {
  background-color: rgb(255, 195, 0, 0.2);
}
</style>
