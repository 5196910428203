<template>
  <div
    v-show="wrapShow"
    id="edit-wrap"
    v-loading="loading"
    class="edit-wrap"
    @click.prevent="clickeWrap"
  >
    <div id="standalone-container">
      <toolbar
        :quill="quill"
        :client="client"
        container="toolbar-container"
      ></toolbar>
      <div id="editor-container-wrap" @scroll="warpScroll">
        <div
          v-show="connected"
          id="editor-container"
          @click="checktext"
          @keydown="handleTextChange"
        ></div>
      </div>
      <span
        v-for="(cursor, index) in userCursorList"
        v-show="cursor.show"
        :key="index"
        class="cursor_span"
        :style="{
          top: cursor.top + 'px',
          left: cursor.left + 'px',
          'background-color': cursor.color,
          color: cursor.color,
          height: cursor.height + 'px',
        }"
        :class="{
          twinkle: cursor.animation,
        }"
      >
        <i
          :style="{
            'background-color': cursor.color,
          }"
          @mouseenter="
            cursor.showName = true;
            cursor.animation = false;
          "
          @mouseout="
            cursor.showName = false;
            cursor.animation = true;
          "
        ></i>
        <span v-if="cursor.showName">{{ cursor.userName }}</span>
      </span>
    </div>
    <div
      v-if="atShow"
      class="at_position"
      :style="{
        left: atShowposition.left - 100 + 'px',
        top: atShowposition.top + 80 + 'px',
      }"
    >
      <at-vue
        ref="atVue"
        :query-string="atQuery"
        @selectPerson="selectPerson"
      ></at-vue>
    </div>
    <div
      v-if="linkShow"
      class="link_position"
      :style="{
        left: linkShowposition.left + 'px',
        top: linkShowposition.top + 20 + 'px',
      }"
    >
      <div class="link" :style="link_map.type == 'card' ? 'padding:0' : ''">
        <el-tooltip
          class="item"
          effect="dark"
          :content="link_map.link"
          placement="top-start"
        >
          <div
            class="link_link"
            v-if="link_map.type == 'link' || link_map.type == 'title'"
          >
            {{ link_map.link }}
          </div>
        </el-tooltip>

        <div
          class="link_edit"
          @click="refresh_target"
          v-if="link_map.type == 'view'"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="刷新"
            placement="top-start"
          >
            <i style="font-size: 16px" class="iconfont icon-refresh"></i>
          </el-tooltip>
        </div>
        <div
          class="link_edit"
          @click="open_target"
          v-if="link_map.type == 'view'"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="打开原网页"
            placement="top-start"
          >
            <i
              style="font-size: 16px"
              class="iconfont icon-a-yonghuzhinan2"
            ></i>
          </el-tooltip>
        </div>
        <div
          class="link_edit"
          @click="link_edit"
          v-if="link_map.type == 'link' || link_map.type == 'title'"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="编辑链接"
            placement="top-start"
          >
            <i style="font-size: 16px" class="iconfont icon-bianji"></i>
          </el-tooltip>
        </div>
        <div
          class="link_remove"
          @click="link_remove"
          v-if="link_map.type == 'link' || link_map.type == 'title'"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="移除链接"
            placement="top-start"
          >
            <i class="iconfont icon-a-huaban1111"></i>
          </el-tooltip>
        </div>
        <div
          class="link_choose"
          :style="link_map.type == 'card' ? 'border:none !important' : ''"
        >
          <el-dropdown placement="bottom-start" @command="change_link_type">
            <el-button
              type="primary"
              class="link-button"
              style="color: #606266; background: white; border: none !important"
            >
              <i
                :class="'iconfont icon-' + link_options_map[link_map.type].icon"
              ></i>
              {{ link_options_map[link_map.type].label
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in link_options"
                :key="item.value"
                :command="link_map.type == item.value ? null : item.value"
                :style="
                  'font-size:16px;' +
                  (link_map.type == item.value ? 'color: #0043a6' : '')
                "
              >
                <i
                  :class="'iconfont icon-' + link_options_map[item.value].icon"
                ></i>
                {{ item.label }}
                <span v-if="link_map.type == item.value"> √</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div
      v-if="edit_link"
      class="edit_link_position"
      :style="{
        left: linkShowposition.left + 'px',
        top: linkShowposition.top + 20 + 'px',
      }"
    >
      <div class="edit_link_position_row">
        <span>文本</span>
        <el-input v-model="edit_link_label" placeholder="请输入文本"></el-input>
      </div>
      <div class="edit_link_position_row">
        <span>链接</span>
        <el-input v-model="edit_link_value" placeholder="请输入链接"></el-input>
      </div>
      <div style="display: flex; justify-content: flex-end">
        <div>
          <el-button @click="edit_link = false">取消</el-button>

          <el-button type="primary" @click="edit_link_save">确认</el-button>
        </div>
      </div>
    </div>

    <div id="menu">
      <div>
        <div
          v-for="(item, index) in isPublic ? rightMenuPub : rightMenu"
          :key="index"
          @click="menuClick(index)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <label style="display: block">
      <textarea ref="text_area" style="opacity: 0"></textarea>
    </label>
  </div>
</template>
<script>
import Quill from "quill";
import "quill/dist/quill.snow.css";
import Stomp from "stompjs";
import SockJS from "sockjs-client";
import { mapGetters } from "vuex";
import {
  editDocument,
  sendAt,
  getContent,
  getSetting,
  internetShare,
} from "@/network/knowledge";
import { get_data } from "@/network/baseline/index.js";
import { get_version_detail } from "@/network/version/index.js";
import { get_sprints_info } from "@/network/quick/index.js";
import { get_single_testPlans } from "@/network/testPlan/index.js";
import { get_dashboard_detail } from "@/network/dataChart/index.js";
import { getSelectedGanttRules } from "@/network/gantt/index.js";
import { postDocumentVersion, get_web_desc } from "@/network/knowledge";
import { ossAuthorization } from "@/utils/oss";
import AtVue from "./At.vue";
import toolbar from "../toolbar.vue";
// import QuillBetterTable from "@/plugins/quill-better-table.js";
import { Message } from "element-ui";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
import vmson from "@/utils/vmson";
import { useTemplate } from "@/network/knowledgeTemplate/index.js";
import { getWatermark } from "@/network/watermask";
import { ulid } from "ulid";

export default {
  components: {
    AtVue,
    toolbar,
  },
  props: {
    documentInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    saveAsVersion: {
      type: Boolean,
      defaule: false,
    },
  },
  data() {
    return {
      edit_link: false,
      linkShow: false,
      edit_link_label: "",
      edit_link_value: "",
      iframe_listen: {},
      linkShowposition: {
        left: 0,
        top: 0,
      },
      link_options_map: {
        link: {
          label: "链接视图",
          icon: "a-huaban6",
        },
        title: {
          label: "标题视图",
          icon: "a-huaban112",
        },
        card: {
          label: "卡片视图",
          icon: "a-huaban110",
        },
        view: {
          label: "预览视图",
          icon: "a-huaban2",
        },
      },
      link_options: [
        {
          value: "link",
          label: "链接视图",
        },
        {
          value: "title",
          label: "标题视图",
        },
        {
          value: "card",
          label: "卡片视图",
        },
        {
          value: "view",
          label: "预览视图",
        },
      ],
      link_map: {
        link: "",
        type: "",
        id: "",
      },
      quill: null,
      formatObject: {},
      connected: false,
      userCursorList: [],
      upload_list: [],
      timer: null,
      loading: false,
      atShow: false,
      wrapShow: false,
      atShowposition: {
        left: 0,
        top: 0,
      },
      atQuery: "",
      isQuerying: false,
      sendAtTimer: null,

      rightMenu: [
        {
          label: this.$t("knowledge.internalSharingLinks"),
        },
      ],
      rightMenuPub: [
        {
          label: this.$t("knowledge.internalSharingLinks"),
        },
        {
          label: this.$t("knowledge.externalSharingLinks"),
        },
      ],
      // 选中行的id
      lineId: "",
      // 存入每次修改的ops
      opsList: [],
      // 记录上一次鼠标选中位置
      lastRange: null,
      lastChange: null,
      isPublic: false,
      // 记录scrollTop
      wrapScrollTop: 0,
      client: null,
      copy_data: {
        copy_list: [],
        hang: 0,
        lie: 0,
      },
      is_copy: false,
    };
  },
  computed: {
    ...mapGetters(["token", "userAccountId", "user_list"]),
  },
  watch: {
    saveAsVersion(newVal, oldVal) {
      if (newVal) {
        this.postVersion();
      }
    },
  },
  async mounted() {
    await ossAuthorization.call(this);
    // 初始化quill
    this.initQuill();
    // // 建立ws链接
    this.initWebscoket();
    this.quill.on("text-change", this.text_change);
    // // 监听光标位置
    this.quill.on("selection-change", this.selection_change);
    // // 创建右键菜单
    this.initRightClick();
    this.getSetting();
    window.addEventListener("beforeunload", this.beforeunload);
    window.addEventListener("keydown", this.add_listener);
    this.$emit("loading");
    vmson.$on("useTemplate", (data) => {
      // 在文段末尾插入模板内容
      this.quill.clipboard.dangerouslyPasteHTML(
        this.quill.getLength(),
        data.content + "<br>"
      );
      const length = this.quill.getLength(); // 获取Quill实例内容的长度
      this.quill.setSelection(length, 0); // 将光标定位到文档末尾(光标位置，光标长度)
      // 视图滚动到末位
      const editor = document.getElementById("editor-container-wrap");
      editor.scrollTop = editor.scrollHeight;
      useTemplate(data.knowledgeTemplateId).then((res) => {
        data.callback();
      });
    });
    let iframes = document.querySelectorAll("iframe");
    let that = this;
    // 为每个iframe添加监听器
    iframes.forEach((ele) => {
      (function (iframe) {
        that.iframe_listen[iframe.id] = function () {
          iframe.addEventListener("mouseover", that.iframeMouseOverListener);
        };
        that.iframe_listen[iframe.id]();
      })(ele);
    });
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.add_listener);
    window.removeEventListener("beforeunload", this.beforeunload);
    this.quill = null;
    this.closeWebsocket();
    let iframes = document.querySelectorAll("iframe");
    iframes.forEach((ele) => {
      this.removeIframeListener(ele.id);
    });
  },
  methods: {
    iframeMouseOverListener(event) {
      this.link_map["link"] = event.target.attributes.link.value;
      this.link_map["type"] = "view";
      this.link_map["id"] = event.target.id;
      let y = event.target.getBoundingClientRect().top;
      this.linkShow = true;
      this.linkShowposition.top = y - 250;
      this.linkShowposition.left = event.target.offsetLeft;
    },
    removeIframeListener(iframeId) {
      let iframe = document.getElementById(iframeId);
      if (iframe) {
        iframe.removeEventListener("mouseover", this.iframeMouseOverListener);
      }
    },
    async change_link_type(e) {
      const hostname = window.location.hostname;

      if (!e) {
        return;
      }

      if (e == "link") {
        // add link embed
        let start_index = 0;
        if (this.link_map.type == "view") {
          start_index = this.view_remove();
        } else if (this.link_map.type == "card") {
          start_index = this.card_remove();
        } else {
          start_index = this.link_remove("update");
        }

        this.quill.insertEmbed(start_index, "my-link", {
          name: this.link_map.link,
          link: this.link_map.link,
          type: "link",
        });
        this.linkShow = false;
      }

      if (e == "title") {
        // add title embed

        if (this.link_map.link.indexOf(hostname) !== -1) {
          let res = await this.get_our_web_title(this.link_map.link);
          let start_index = 0;
          if (this.link_map.type == "view") {
            start_index = this.view_remove();
          } else if (this.link_map.type == "card") {
            start_index = this.card_remove();
          } else {
            start_index = this.link_remove("update");
          }
          this.quill.insertEmbed(start_index, "my-link", {
            name: res,
            link: this.link_map.link,
            type: "title",
          });
          this.linkShow = false;
        } else {
          get_web_desc({ url: this.link_map.link }).then((res) => {
            let start_index = 0;
            if (this.link_map.type == "view") {
              start_index = this.view_remove();
            } else if (this.link_map.type == "card") {
              start_index = this.card_remove();
            } else {
              start_index = this.link_remove("update");
            }
            this.quill.insertEmbed(start_index, "my-link", {
              name: res.title ? res.title : "无标题",
              link: this.link_map.link,
              type: "title",
            });
            this.linkShow = false;
          });
        }
      }

      if (e == "card") {
        let card_id = ulid();

        if (this.link_map.link.indexOf(hostname) !== -1) {
          let res = await this.get_our_web_title(this.link_map.link);
          let start_index = 0;
          if (this.link_map.type == "view") {
            start_index = this.view_remove();
          } else {
            start_index = this.link_remove("update");
          }

          this.quill.insertEmbed(start_index, "my-card_link", {
            link: this.link_map.link,
            type: "card",
            card_id: card_id,
          });
          this.quill.insertEmbed(
            start_index + this.link_map.link.length,
            "my-title",
            {
              link: this.link_map.link,
              type: "card",
              card_id: card_id,
              title: res,
            }
          );
          this.quill.insertEmbed(
            start_index + res.length + this.link_map.link.length,
            "my-desc",
            {
              link: this.link_map.link,
              type: "card",
              card_id: card_id,
              desc: "Agile·ASPICE·AI 汽车行业一站式需求、开发、测试管理平台",
            }
          );
          this.linkShow = false;
        } else {
          get_web_desc({ url: this.link_map.link }).then((res) => {
            let start_index = 0;
            if (this.link_map.type == "view") {
              start_index = this.view_remove();
            } else {
              start_index = this.link_remove("update");
            }

            this.quill.insertEmbed(start_index, "my-card_link", {
              link: this.link_map.link,
              type: "card",
              card_id: card_id,
            });
            this.quill.insertEmbed(
              start_index + this.link_map.link.length,
              "my-title",
              {
                link: this.link_map.link,
                type: "card",
                card_id: card_id,
                title: res.title ? res.title : "  ",
              }
            );
            this.quill.insertEmbed(
              start_index + res.title.length + this.link_map.link.length,
              "my-desc",
              {
                link: this.link_map.link,
                type: "card",
                card_id: card_id,
                desc: res.description ? res.description : "  ",
              }
            );
            this.linkShow = false;
          });
        }
        // add card embed
      }

      if (e == "view") {
        // add title embed
        let start_index = 0;

        if (this.link_map.type == "card") {
          start_index = this.card_remove();
        } else {
          start_index = this.link_remove("update");
        }
        this.quill.insertEmbed(start_index, "my-view", {
          link: this.link_map.link,
          type: "title",
        });
        this.linkShow = false;
        this.$nextTick(() => {
          let iframes = document.querySelectorAll("iframe");
          iframes.forEach((ele) => {
            this.removeIframeListener(ele.id);
          });
          let that = this;
          // 为每个iframe添加监听器
          iframes.forEach((ele) => {
            // 使用闭包来确保this和ele在监听器函数内部是可访问的
            (function (iframe) {
              that.iframe_listen[iframe.id] = function () {
                iframe.addEventListener(
                  "mouseover",
                  that.iframeMouseOverListener
                );
              };
              that.iframe_listen[iframe.id]();
            })(ele);
          });
        });
      }
    },
    link_edit() {
      // 编辑链接
      this.linkShow = false;
      this.edit_link = true;
      this.edit_link_label = document.getElementById(
        this.link_map.id
      ).innerText;
      this.edit_link_value = this.link_map.link;
    },
    edit_link_save() {
      if (this.edit_link_label.length < 1) {
        this.$message({
          type: "warning",
          message: "请输入文本",
        });
        return;
      }
      if (this.edit_link_value.length < 1) {
        this.$message({
          type: "warning",
          message: "请输入链接",
        });
        return;
      }
      let start_index = this.link_remove("update");

      this.quill.insertEmbed(start_index, "my-link", {
        name: this.edit_link_label,
        link: this.edit_link_value,
        type: this.link_map.type,
      });
    },
    card_remove(card_id) {
      if (!this.quill.getContents().ops) {
        return;
      }
      let index = 0;
      let save_index = 0;
      let raw_id = card_id ? card_id : this.link_map.id;

      raw_id = raw_id.split("#")[1];
      for (let i of this.quill.getContents().ops) {
        if (i.insert["image"]) {
          index += 1;
        } else {
          index += i.insert.length;

          if (
            !i.attributes ||
            !(
              i.attributes["my-title"] ||
              i.attributes["my-desc"] ||
              i.attributes["my-card_link"]
            )
          ) {
            continue;
          }

          if (i.attributes["my-title"]) {
            if (i.attributes["my-title"].id.split("#")[1] == raw_id) {
              this.quill.deleteText(index - i.insert.length, i.insert.length);
              index -= i.insert.length;
              save_index = index;
            }
          }
          if (i.attributes["my-desc"]) {
            if (i.attributes["my-desc"].id.split("#")[1] == raw_id) {
              this.quill.deleteText(index - i.insert.length, i.insert.length);
              index -= i.insert.length;
              save_index = index;
            }
          }
          if (i.attributes["my-card_link"]) {
            if (i.attributes["my-card_link"].id.split("#")[1] == raw_id) {
              this.quill.deleteText(index - i.insert.length, i.insert.length);
              index -= i.insert.length;
              save_index = index;
            }
          }
        }
      }
      this.linkShow = false;

      return save_index;
    },
    view_remove() {
      if (!this.quill.getContents().ops) {
        return;
      }
      let index = 0;
      for (let i of this.quill.getContents().ops) {
        if (i.insert["image"]) {
          index += 1;
        } else {
          index += i.insert.length;
          if (!i.attributes || !i.attributes["my-view"]) {
            continue;
          }
          if (i.attributes["my-view"].id == this.link_map.id) {
            this.quill.deleteText(index - i.insert.length, i.insert.length);
            return index - i.insert.length;
          }
        }
      }
      this.linkShow = false;
    },
    link_remove(mode, link_id) {
      let raw_id = link_id ? link_id : this.link_map.id;
      if (!this.quill.getContents().ops) {
        return;
      }
      let index = 0;
      for (let i of this.quill.getContents().ops) {
        if (i.insert["image"]) {
          index += 1;
        } else {
          index += i.insert.length;
          if (!i.attributes || !i.attributes["my-link"]) {
            continue;
          }
          if (i.attributes["my-link"].id == raw_id) {
            this.quill.deleteText(index - i.insert.length, i.insert.length);
            if (mode == "update") {
              this.edit_link = false;
              return index - i.insert.length;
            } else {
              this.quill.insertText(index - i.insert.length, i.insert);
              this.quill.setSelection(index, 0);
              this.linkShow = false;
              this.edit_link = false;
            }
          }
        }
      }
    },
    open_target() {
      let l = this.link_map.link;
      window.open((l.slice(0, 4) == "http" ? "" : "//") + l, "_blank");
    },
    refresh_target() {
      let iframe = document.getElementById(this.link_map.id);
      iframe.src = "";
      iframe.src = this.link_map.link;
    },
    add_listener(event) {
      let that = this;
      if (
        (event.ctrlKey && event.key === "a") ||
        (event.ctrlKey && event.key === "A")
      ) {
        if (
          !(
            that.quill.getModule("better-table") &&
            that.quill.getModule("better-table").tableSelection
          )
        ) {
          return;
        }

        let dic =
          that.quill.getModule("better-table").tableSelection.selectedTds;

        // 如果选区无单元格，则返回
        if (dic.length == 1) {
          let range = document.createRange();
          range.selectNodeContents(dic[0].domNode);
          let selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
          event.preventDefault();
        }
      }

      if (event.key === "Backspace" || event.key === "Delete") {
        let index = 0;
        let quillSelectionIndex = that.quill.getSelection().index; // Quill的选择索引
        let condition = true;
        for (let i of that.quill.getContents().ops) {
          if (i.insert["image"]) {
            index += 1;
          } else {
            index += i.insert.length;

            condition =
              event.key === "Backspace"
                ? index >= quillSelectionIndex
                : index > quillSelectionIndex;

            if (condition) {
              if (i.attributes && i.attributes["my-title"]) {
                that.card_remove(i.attributes["my-title"].id);
                return;
              }
              if (i.attributes && i.attributes["my-desc"]) {
                that.card_remove(i.attributes["my-desc"].id);
                return;
              }
              if (i.attributes && i.attributes["my-card_link"]) {
                that.card_remove(i.attributes["my-card_link"].id);
                return;
              }
              if (i.attributes && i.attributes["my-link"]) {
                that.link_remove("update", i.attributes["my-link"].id);
                return;
              }

              break;
            }
          }
        }
        if (
          !(
            that.quill.getModule("better-table") &&
            that.quill.getModule("better-table").tableSelection
          )
        ) {
          return;
        }

        let dic =
          that.quill.getModule("better-table").tableSelection.selectedTds;

        //
        if (dic.length > 1) {
          that.del();
          event.preventDefault();
        } else {
          if (event.key === "Delete") {
          }
        }
      }

      if (
        (event.ctrlKey && event.key === "x") ||
        (event.ctrlKey && event.key === "X")
      ) {
        if (
          !(
            that.quill.getModule("better-table") &&
            that.quill.getModule("better-table").tableSelection
          )
        ) {
          that.is_copy = false;
          return;
        }

        let dic =
          that.quill.getModule("better-table").tableSelection.selectedTds;

        // 如果选中了文字，则取消复制表格
        if (that.quill.getSelection() && that.quill.getSelection().length > 0) {
          that.is_copy = false;
          return;
        }

        // 如果选区无单元格，则返回
        if (dic.length > 0) {
          that.is_copy = true;
          that.copy();
          that.del();
          event.preventDefault();
        } else {
          that.is_copy = false;
          return;
        }
      }

      if (
        (event.ctrlKey && event.key === "c") ||
        (event.ctrlKey && event.key === "C")
      ) {
        if (
          !(
            that.quill.getModule("better-table") &&
            that.quill.getModule("better-table").tableSelection
          )
        ) {
          that.is_copy = false;
          return;
        }

        let dic =
          that.quill.getModule("better-table").tableSelection.selectedTds;

        // 如果选中了文字，则取消复制表格
        if (that.quill.getSelection() && that.quill.getSelection().length > 0) {
          that.is_copy = false;
          return;
        }
        // 如果选区无单元格，则返回
        if (dic.length > 0) {
          that.is_copy = true;
          that.copy();
          event.preventDefault();
        } else {
          that.is_copy = false;
          return;
        }
      }
      if (
        (event.ctrlKey && event.key === "v") ||
        (event.ctrlKey && event.key === "V")
      ) {
        if (
          !(
            that.quill.getModule("better-table") &&
            that.quill.getModule("better-table").tableSelection
          )
        ) {
          return;
        }
        // 如果不在复制模式，则返回
        if (!that.is_copy) {
          return;
        }

        // 如果选中了文字，则取消复制表格
        if (that.quill.getSelection() && that.quill.getSelection().length > 0) {
          return;
        }

        // 如果选区无单元格，则返回
        let dic =
          that.quill.getModule("better-table").tableSelection.selectedTds;
        if (dic.length < 1) {
          return;
        }

        that.parse();
        event.preventDefault();
      }
    },
    empty_dom(row, cell) {
      return `"<p class="qlbt-cell-line" data-row="${row}" data-cell="${cell}" data-rowspan="1" data-colspan="1"><br></p>"`;
    },
    del() {
      let dic = this.quill.getModule("better-table").tableSelection.selectedTds;
      dic.forEach((item) => {
        let origin_dom = item.domNode.innerHTML;
        let origin_row = origin_dom.match(/data-row=\"(.*?)\"/);
        let origin_cell = origin_dom.match(/data-cell=\"(.*?)\"/);
        item.domNode.innerHTML = this.empty_dom(origin_row[1], origin_cell[1]);
      });
    },
    get_strat(matrix, c, d, a, b) {
      this.start = null;
      let start;
      for (let i = 0; i < matrix.length; i++) {
        for (let j = 0; j < matrix[i].length; j++) {
          if (
            matrix[i][j].childNodes[0].attributes["data-cell"].nodeValue == c
          ) {
            if (
              matrix[i][j].childNodes[0].attributes["data-row"].nodeValue == d
            ) {
              start = [i, j];
              this.start = start;
              return;
            }
          }
        }
      }
      if (!start) {
        return false;
      }
      if (start[0] + a > matrix.length || start[1] + b > matrix[0].length) {
        return false;
      }
      return true;
    },
    parse() {
      let dic = this.quill.getModule("better-table").tableSelection.selectedTds;
      // 获取表格二维数组
      let table = [];
      this.quill.getModule("better-table").table.rows.forEach((item) => {
        let l = [];
        item.childNodes.forEach((ele) => {
          l.push(ele);
        });
        table.push(l);
      });
      // 判断表格的长度宽度是否满足复制
      let htmlString = dic[0].domNode.innerHTML;
      let parser = new DOMParser();
      let doc = parser.parseFromString(htmlString, "text/html");
      let element = doc.querySelector(".qlbt-cell-line");
      let dataCell = element.getAttribute("data-cell");
      this.get_strat(
        table,
        dataCell,
        element.getAttribute("data-row"),
        this.copy_data.hang - 1,
        this.copy_data.lie - 1
      );
      // 如果表格的长度宽度可以满足复制
      let num = 0;
      let x1 = this.start[0];
      let y1 = this.start[1];
      let x2 = x1 + this.copy_data.hang;
      let y2 = y1 + this.copy_data.lie;
      let i = 0;
      this.quill.getModule("better-table").table.rows.forEach((item) => {
        let j = 0;
        item.childNodes.forEach((ele) => {
          if (x1 <= i && i < x2 && y1 <= j && j < y2) {
            while ((num % this.copy_data.lie) + y1 >= table[0].length) {
              num++;
            }
            let cell = ele.childNodes[0].attributes["data-cell"].nodeValue;
            let row = ele.childNodes[0].attributes["data-row"].nodeValue;

            let parser = new DOMParser();
            let doc = parser.parseFromString(
              this.copy_data.copy_list[num].con,
              "text/html"
            );

            let pElement = doc.querySelectorAll(".qlbt-cell-line");

            pElement.forEach((ele_item) => {
              ele_item.setAttribute("data-cell", cell);
            });

            let updatedHtmlString = doc.body.innerHTML;

            let parser2 = new DOMParser();
            let doc2 = parser2.parseFromString(updatedHtmlString, "text/html");
            let pElement2 = doc2.querySelectorAll(".qlbt-cell-line");
            pElement2.forEach((ele_item) => {
              ele_item.setAttribute("data-row", row);
            });
            // pElement2.setAttribute("data-row", row);
            let result = doc2.body.innerHTML;

            ele.innerHTML = result;
            num++;
          }
          j++;
        });
        i++;
      });
    },

    copy() {
      let copy_list = [];
      let dic = this.quill.getModule("better-table").tableSelection.selectedTds;
      // 获取选区矩阵
      for (let i of Object.keys(dic)) {
        copy_list.push(dic[i].domNode.innerHTML);
      }

      let arr = this.parseArray(copy_list); // [{data-cell,data-row,con},{}]

      // 获取矩阵行列
      let s = new Set();
      arr.forEach((i) => {
        s.add(i["data-row"]);
      });
      let hang = s.size;
      let lie = arr.length / s.size;

      // 整合数据
      this.copy_data = {
        copy_list: arr,
        hang: hang,
        lie: lie,
      };
    },
    parseArray(arr) {
      // 获取html元素的data-row data-cell数据
      let result = arr.map(function (item) {
        let rowMatch = item.match(/data-row=\"(.*?)\"/);
        let cellMatch = item.match(/data-cell=\"(.*?)\"/);
        return {
          "data-row": rowMatch ? rowMatch[1] : null,
          "data-cell": cellMatch ? cellMatch[1] : null,
          con: item,
        };
      });
      return result;
    },
    //滚动事件
    warpScroll(e) {
      this.wrapScrollTop = e.target.scrollTop;
    },
    checktext(event) {
      let selection = window.getSelection();
      let range = selection.getRangeAt(0);
      let commonAncestor = range.commonAncestorContainer;

      if (
        selection.toString() != "" &&
        (commonAncestor.id == undefined || commonAncestor.id != "")
      ) {
        this.setDownposition();
        event.stopPropagation();
      }
    },
    beforeunload(e) {
      const confirmationMessage = "\o/";
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage;
    },
    getSetting() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.documentInfo.documentId,
      };
      getSetting(params).then((res) => {
        if (res.knowledgeAuthorities.indexOf("INTERNET_READ") !== -1) {
          this.isPublic = true;
        } else {
          this.isPublic = false;
        }
      });
    },
    initRightClick() {
      const self = this;
      const menu = document.getElementById("menu");
      const wrap = document.getElementById("editor-container");
      const wrap2 = document.getElementById("edit-wrap");
      const toolbar = document.getElementById("toolbar-container");
      wrap.oncontextmenu = function (evt) {
        self.setDownposition(evt);
      };
      wrap2.onclick = function () {
        self.lineId && document.getElementById(self.lineId)
          ? document
              .getElementById(self.lineId)
              .classList.remove("knowledge-selected-Line")
          : "";
        menu.style.display = "none";
      };
    },
    setDownposition(evt) {
      const self = this;
      const menu = document.getElementById("menu");
      const wrap = document.getElementById("editor-container");
      const wrap2 = document.getElementById("edit-wrap");
      const toolbar = document.getElementById("toolbar-container");
      // 自定义body元素的鼠标事件处理函数
      const e = evt || window.event;
      e.preventDefault(); //阻止系统右键菜单
      self.lineId && document.getElementById(self.lineId)
        ? document
            .getElementById(self.lineId)
            .classList.remove("knowledge-selected-Line")
        : "";
      // 显示自定义的菜单调整位置
      self.lineId = e.target.id ? e.target.id : e.target.parentNode.id;
      document.getElementById(self.lineId)
        ? document
            .getElementById(self.lineId)
            .classList.add("knowledge-selected-Line")
        : "";
      if (self.lineId) {
        menu.style.display = "block";
        menu.style.left = e.layerX + "px";
        menu.style.top = e.pageY - 2 * toolbar.clientHeight + "px";
      }
    },
    // 右键菜单点击事件
    menuClick(index) {
      switch (index) {
        case 0:
          if (this.lineId) {
            const link = window.location.href.split("?")[0];
            const query = `?documentId=${this.documentInfo.documentId}&elementId=${this.lineId}`;
            let text1 = this.$refs["text_area"];
            text1.innerText = link + query;
            text1.select();
            document.execCommand("copy");
            this.$message({
              type: "success",
              message: this.$t("knowledge.copiedLink"),
            });
          }
          break;
        case 1:
          if (this.lineId) {
            const params = {
              projectId: this.get_pid(),
              documentId: this.documentInfo.documentId,
            };
            internetShare(params).then((res) => {
              const url = res.url;
              const link = url + "?elementId=" + this.lineId;
              let text1 = this.$refs["text_area"];
              text1.innerText = link;
              text1.select();
              document.execCommand("copy");
              this.$message({
                type: "success",
                message: this.$t("knowledge.copiedLink"),
              });
            });
          }
          break;
      }
    },
    // 选择用户
    selectPerson(item) {
      const index = this.quill.getSelection().index - this.atQuery.length;
      this.atShow = false;
      this.isQuerying = false;
      this.quill.deleteText(index - 1, 1 + this.atQuery.length);
      this.quill.insertEmbed(index - 1, "my-span", {
        name: "@" + item.nickname,
        accountId: item.accountId,
      });
      this.quill.insertEmbed(index + item.nickname.length, "text", " ");
      setTimeout(
        () =>
          this.quill.setSelection(
            this.quill.getSelection().index + item.nickname.length + 2,
            0
          ),
        100
      );
      const data = this.quill.getContents(index, 1).ops[0].attributes[
        "my-span"
      ];
      const obj = {
        atAccountId: data.accountId,
        elementId: data.id,
      };
      this.sendAt(obj);
    },
    // 关闭@
    clickeWrap() {
      this.atShow = false;
      this.isQuerying = false;
    },
    // 粘贴图片
    pasteFile(evt) {
      // 获取粘贴的html 和text 并判断是否包含img
      const html = evt.clipboardData.getData("text/html");
      const hasImg = /<img\b[^>]*>/.test(html);
      const text = evt.clipboardData.getData("text/plain");
      if (
        evt.clipboardData &&
        evt.clipboardData.files &&
        evt.clipboardData.files.length
      ) {
        [].forEach.call(evt.clipboardData.files, async (file, index) => {
          if (file.size / (1024 * 1024) >= 50) {
            this.$message({
              type: "warning",
              message: this.$t("knowledge.tooLarge"),
            });
            this.loading = false;
            return;
          }
          if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
            this.loading = true;
            const res = await this.client.put(
              `${
                this.$store.state.project.tenantId
              }/comment/${new Date().getTime()}${file.name}`,
              file
            );
            const range = this.quill.getSelection();
            if (range) {
              let length = this.quill.getSelection().index; // 获取内容长度
              this.quill.insertEmbed(length, "link", {
                href: res.url,
                innerText: file.name,
              }); // 插入链接
              const num = (file.name && file.name.length) || 0;
              this.quill.insertText(length + num, "、", true);
              this.quill.setSelection(length + num + 1); // 设置光标位置
              this.loading = false;
            }
          }
        });
      } else if (!hasImg) {
        // evt.preventDefault();
        // 判断是否是网络链接
        let re = new RegExp("^(http|https)://", "i");
        const isHttp = re.test(text);
        const index = this.quill.getSelection().index;
        if (isHttp) {
          evt.preventDefault();
          this.linkType(text, index);
        } else {
          return true;
          // this.quill.clipboard.dangerouslyPasteHTML(index, html);
        }
      }
    },
    // 不同链接的不同处理方式
    linkType(text, index) {
      const hostname = window.location.hostname;

      if (text.indexOf(hostname) !== -1) {
        this.quill.insertEmbed(index, "my-view", {
          link: text,
          type: "title",
        });
        this.linkShow = false;
        this.$nextTick(() => {
          let iframes = document.querySelectorAll("iframe");
          iframes.forEach((ele) => {
            this.removeIframeListener(ele.id);
          });
          let that = this;
          // 为每个iframe添加监听器
          iframes.forEach((ele) => {
            // 使用闭包来确保this和ele在监听器函数内部是可访问的
            (function (iframe) {
              that.iframe_listen[iframe.id] = function () {
                iframe.addEventListener(
                  "mouseover",
                  that.iframeMouseOverListener
                );
              };
              that.iframe_listen[iframe.id]();
            })(ele);
          });
        });
        this.quill.setSelection(index + text.length + 1);
      } else {
        this.quill.insertEmbed(index, "my-link", {
          name: text,
          link: text,
          type: "link",
        });
        this.quill.setSelection(index + text.length + 1);
      }
    },
    async get_our_web_title(text) {
      const hostname = window.location.hostname;

      // 匹配pid的正则
      const pidReg = /\/\/[^\/]+\/([^\/\?]+)/;
      const pid = pidReg.exec(text) ? pidReg.exec(text)[1] : "";

      if (
        text.indexOf("knowledge?documentId=") !== -1 &&
        text.indexOf("elementId") !== -1 &&
        text.indexOf(hostname) !== -1
      ) {
        // 匹配参数的正则表达式
        const reg = /[?&]([^=&#]+)=([^&#]*)/g;
        // 存储参数的对象
        const query = {};
        let match;
        while ((match = reg.exec(text))) {
          // 将参数存储到对象中
          query[match[1]] = match[2];
        }
        getContent({
          projectId: pid,
          documentId: query.documentId,
        }).then((res) => {
          return res;
        });
      } else if (
        text.indexOf("knowledge?documentId=") !== -1 &&
        text.indexOf("title") !== -1 &&
        text.indexOf(hostname) !== -1
      ) {
        // 匹配参数的正则表达式
        const reg = /[?&]([^=&#]+)=([^&#]*)/g;
        // 匹配pid的正则
        // 存储参数的对象
        const query = {};
        let match;
        while ((match = reg.exec(text))) {
          // 将参数存储到对象中
          query[match[1]] = match[2];
        }
        return query.title;
      } else if (
        text.indexOf(hostname) !== -1 &&
        (text.indexOf("/myMind/") !== -1 || text.indexOf("/lineword/") !== -1)
      ) {
        const arr = text.split("/");
        const id = arr[arr.length - 1];
        let linkText = "";
        if (id.indexOf("?") !== -1) {
          linkText = id.split("?")[0];
        } else {
          linkText = id;
        }
        return "文件-" + linkText;
      } else if (
        text.indexOf(hostname) !== -1 &&
        text.indexOf("personalDataSummary") !== -1
      ) {
        return "个人数据汇总";
      } else if (
        text.indexOf(hostname) !== -1 &&
        text.indexOf("projectDataSummary") !== -1
      ) {
        return "项目数据汇总";
      } else if (
        text.indexOf(hostname) !== -1 &&
        text.indexOf("/nodes/key/") !== -1
      ) {
        const arr = text.split("/");
        const id = arr[arr.length - 1];
        let linkText = "";
        if (id.indexOf("?") !== -1) {
          linkText = id.split("?")[0];
        } else {
          linkText = id;
        }
        return "节点-" + linkText;
      } else if (
        text.indexOf("/release/baseline/") !== -1 &&
        text.indexOf(hostname) !== -1
      ) {
        const arr = text.split("/");
        const id = arr[arr.length - 1];
        let linkText = "";
        if (id.indexOf("?") !== -1) {
          linkText = id.split("?")[0];
        } else {
          linkText = id;
        }
        let name = "MappingSpace";
        try {
          const sprintName = await get_data(pid, linkText);
          name = "基线-" + sprintName.name;
        } catch (error) {}
        return name;
      } else if (
        text.indexOf("/release/version/") !== -1 &&
        text.indexOf(hostname) !== -1
      ) {
        const arr = text.split("/");
        const id = arr[arr.length - 1];
        let linkText = "";
        if (id.indexOf("?") !== -1) {
          linkText = id.split("?")[0];
        } else {
          linkText = id;
        }
        let name = "MappingSpace";
        try {
          const sprintName = await get_version_detail(pid, linkText);
          name = "版本-" + sprintName.name;
        } catch (error) {}
        return name;
      } else if (
        text.indexOf("/release/test/") !== -1 &&
        text.indexOf(hostname) !== -1
      ) {
        const arr = text.split("/");
        const id = arr[arr.length - 1];
        let linkText = "";
        if (id.indexOf("?") !== -1) {
          linkText = id.split("?")[0];
        } else {
          linkText = id;
        }
        let name = "MappingSpace";
        try {
          const sprintName = await get_single_testPlans(pid, linkText);
          name = "测试-" + sprintName.name;
        } catch (error) {}
        return name;
      } else if (
        text.indexOf("/home/board/") !== -1 &&
        text.indexOf(hostname) !== -1
      ) {
        const arr = text.split("/");
        const id = arr[arr.length - 1];
        let linkText = "";
        if (id.indexOf("?") !== -1) {
          linkText = id.split("?")[0];
        } else {
          linkText = id;
        }
        const sprintName = await this.fetchSprintInfo(pid, linkText);
        return sprintName;
      } else if (
        text.indexOf("/home/chart/") !== -1 &&
        text.indexOf(hostname) !== -1
      ) {
        const arr = text.split("/");
        const id = arr[arr.length - 1];
        let linkText = "";
        if (id.indexOf("?") !== -1) {
          linkText = id.split("?")[0];
        } else {
          linkText = id;
        }

        let name = "MappingSpace";
        try {
          const sprintName = await get_dashboard_detail(pid, linkText);
          name = "数据报表-" + sprintName.name;
        } catch (error) {}
        return name;
      } else if (
        text.indexOf("/home/gantt/") !== -1 &&
        text.indexOf(hostname) !== -1
      ) {
        const arr = text.split("/");
        const id = arr[arr.length - 1];
        let linkText = "";
        if (id.indexOf("?") !== -1) {
          linkText = id.split("?")[0];
        } else {
          linkText = id;
        }
        let name = "MappingSpace";
        try {
          const sprintName = await getSelectedGanttRules(pid, linkText);
          name = "甘特图-" + sprintName.name;
        } catch (error) {}
        return name;
      } else {
        return "MappingSpace";
      }
    },
    async fetchSprintInfo(pid, linkText) {
      try {
        const res = await get_sprints_info({
          projectId: pid,
          sprintId: linkText,
        });
        return "sprint-" + res.name;
      } catch (error) {
        console.error("获取Sprint信息失败:", error);
        return "MappingSpace"; // 当出错时返回一个默认值
      }
    },
    // 随机色
    choseRgb() {
      // Math.random是生成0-1之间的随机数 *256 的范围就变成0.xx-255.7
      // Math.floor 向下取整就变成 0-255
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);
      // 拼接返回
      return `rgb(${r},${g},${b})`;
    },
    async initWebscoket() {
      await getWatermark();
      const self = this;
      let socket = new SockJS(
        `${this.wsurl}${
          this.wsurl ? "" : "/prod-api"
        }/file-manage-service/sendServer?accountId=${this.userAccountId}`
      );
      this.stompClient = Stomp.over(socket);
      this.stompClient.connect(
        {
          token: this.token,
          projectId: this.get_pid(),
          scene: "DOC_CONTENT_MODIFY",
        }, //传递token
        (frame) => {
          // 测试topic
          this.connected = true;
          this.stompClient.subscribe(
            `/topic/DOC_CONTENT_MODIFY/${this.get_pid()}/${
              this.documentInfo.documentId
            }`,
            (res) => {
              if (
                res.body &&
                JSON.parse(res.body).accountId !== this.userAccountId
              ) {
                const user = JSON.parse(res.body).accountId;
                if (JSON.parse(res.body).ops) {
                  const ops = JSON.parse(JSON.parse(res.body).ops);
                  if (ops && ops.length) {
                    ops.forEach((item) => {
                      this.quill.updateContents(item, "silent");
                    });
                  }
                }
                let has = false;
                const selection = JSON.parse(JSON.parse(res.body).selection);
                if (!selection) {
                  for (let i = 0; i < this.userCursorList.length; i++) {
                    if (
                      this.userCursorList[i].userAccountId === user &&
                      !selection
                    ) {
                      this.userCursorList.splice(i, 1);
                      this.$emit("userlistchange", this.userCursorList);
                      return;
                    }
                  }
                }
                const bounds = this.quill.getBounds(
                  selection.index,
                  selection.length
                );
                this.userCursorList.forEach((item) => {
                  if (item.userAccountId === user) {
                    has = true;
                    item.left = bounds.left + 10;
                    item.top =
                      bounds.top +
                      +document.getElementById("toolbar-container")
                        .offsetHeight;
                    item.height = bounds.height;
                    item.index = selection.index;
                    item.length = selection.length;
                  }
                });
                if (!has) {
                  this.userCursorList.push({
                    userName: this.matchUserInfo(user)
                      ? this.matchUserInfo(user).nickname
                      : user + "inter",
                    show: true,
                    userAccountId: user,
                    avatar: this.matchUserInfo(user)
                      ? this.matchUserInfo(user).avatar
                      : user,
                    left: bounds.left + 10,
                    top:
                      bounds.top +
                      document.getElementById("toolbar-container").offsetHeight,
                    height: bounds.height,
                    index: selection.index,
                    length: selection.length,
                    color: this.choseRgb(),
                    showName: false,
                    animation: true,
                  });
                }
              }
              this.$emit("userlistchange", this.userCursorList);
            }
          );
        },
        (err) => {
          console.log("错误：" + err);
        }
      );
      this.stompClient.heartbeat.outgoing = 20000; //若使用STOMP 1.1 版本，默认开启了心跳检测机制（默认值都是10000ms）
      this.stompClient.heartbeat.incoming = 0; //客户端不从服务端接收心跳包
    },
    closeWebsocket() {
      this.stompClient.send(
        `/app/EDIT_DOC_CONTENT/${this.get_pid()}/${
          this.documentInfo.documentId
        }`,
        {},
        JSON.stringify({
          tenantId: this.$store.state.project.tenantId,
          accountId: this.userAccountId,
          selection: null,
        })
      );
      if (this.stompClient) {
        try {
          this.stompClient.disconnect(() => {});
        } catch (e) {
          console.log(e);
        }
      }
    },

    handleTextChange(event) {
      const self = this;
      let selection = window.getSelection();
      let range = selection.getRangeAt(0);
      let position = this.quill.getSelection();
      let commonAncestor = range.commonAncestorContainer.parentElement;
      if (event.keyCode === 8) {
        if (
          commonAncestor.style.color == "rgb(64, 158, 255)" &&
          commonAncestor.className == "my-at"
        ) {
          const parentE = commonAncestor.parentNode;
          parentE.removeChild(commonAncestor);
          setTimeout(() => {
            self.quill.setSelection(
              position.index - commonAncestor.innerText.length,
              0
            );
          }, 0);
        }
      }
    },
    initQuill() {
      const self = this;
      this.quill = new Quill("#editor-container", {
        modules: {
          syntax: {
            hljs: {
              highlight: function (language, text) {
                // 根据换行符或回车分割成字符串数组
                function getLines(text) {
                  if (text.length === 0) return [];
                  return text.split(/\r\n|\r|\n/g);
                }

                // TODO:显示行号
                let highText = hljs.highlight(language, text);
                const lines = getLines(highText.value);
                return highText;
              },
            },
          },
          toolbar: {
            container: "#toolbar-container",
          },
          history: {
            delay: 2000,
            maxStack: 500,
          },
          ImageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
          table: false, // disable table module
          "better-table": {
            operationMenu: {
              items: {
                insertColumnRight: {
                  text: this.$t("knowledge.InsertRight"),
                },
                insertColumnLeft: {
                  text: this.$t("knowledge.InsertLeft"),
                },
                insertRowUp: {
                  text: this.$t("knowledge.InsertAbove"),
                },
                insertRowDown: {
                  text: this.$t("knowledge.InsertBelow"),
                },
                mergeCells: {
                  text: this.$t("knowledge.MergeCells"),
                },
                unmergeCells: {
                  text: this.$t("knowledge.SplitCells"),
                },
                deleteColumn: {
                  text: this.$t("knowledge.DeleteColumn"),
                },
                deleteRow: {
                  text: this.$t("knowledge.DeleteRow"),
                },
                deleteTable: {
                  text: this.$t("knowledge.DeleteTable"),
                },
              },
            },
          },
          keyboard: {},
        },
        theme: "snow",
      });
      this.setContent();
      this.$emit("focusTitle");
      this.quill.format("font", "思源黑体");
      // this.quill.format("lineHeight", "2");
      this.quill.root.addEventListener("paste", this.pasteFile, true);
      // 修复bug:修改字体大小或颜色等等，会跳转到页面顶部的问题
      document
        .getElementById("toolbar-container")
        .addEventListener("mousedown", function (e) {
          e.preventDefault();
          e.stopPropagation();
        });
      // 清空富文本的历史操作记录
      this.quill.history.stack.redo = [];
      this.quill.history.stack.undo = [];
      this.wrapShow = true;
      // 给链接添加提示，设置true在捕获阶段执行，优先于原监听事件
      this.quill.container.addEventListener("click", (e) => {
        const activeCards = document.querySelectorAll(".active-card");
        activeCards.forEach((card) => card.classList.remove("active-card"));
        if (e.target.id.split("#")[0] == "link") {
          this.link_map["link"] = e.target.attributes.link.value;
          this.link_map["label"] = e.target.innerText;
          this.link_map["type"] = e.target.attributes.type.value;
          this.link_map["id"] = e.target.id;
          this.linkPosition(e);
          this.linkShow = true;
          let id_list = e.target.id.split("#");
          if (
            id_list[id_list.length - 1] == "title" ||
            id_list[id_list.length - 1] == "desc" ||
            id_list[id_list.length - 1] == "card_link"
          ) {
            document
              .getElementById(
                "link#" + e.target.id.split("#")[1] + "#card_link"
              )
              .classList.add("active-card");
            document
              .getElementById("link#" + e.target.id.split("#")[1] + "#desc")
              .classList.add("active-card");
            document
              .getElementById("link#" + e.target.id.split("#")[1] + "#title")
              .classList.add("active-card");
          }
        } else {
          this.linkShow = false;
        }
        const content = self.quill.getContents();
        if (
          e.target.attributes.class &&
          e.target.attributes.class.value === "ql-editor" &&
          content.ops[content.ops.length - 1] &&
          content.ops[content.ops.length - 1].insert === "\n" &&
          (!content.ops[content.ops.length - 2] ||
            (content.ops[content.ops.length - 2] &&
              content.ops[content.ops.length - 2].insert !== "\n"))
        ) {
          self.quill.insertEmbed(self.quill.getLength(), "block", true, "api");
          self.quill.setSelection(self.quill.getLength());
        }
      });
    },
    linkPosition(event) {
      const clickX = event.clientX;
      const clickY = event.clientY;
      const windowWidth = 540;
      const windowHeight = 50;
      const viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      let left = clickX;
      if (left + windowWidth > viewportWidth) {
        left = viewportWidth - windowWidth;
      }
      if (left < 0) {
        left = 0;
      }
      let top = clickY;
      if (top + windowHeight > viewportHeight) {
        top = viewportHeight - windowHeight;
      }
      if (top < 0) {
        top = 0;
      }
      this.linkShowposition.left =
        left - document.getElementsByClassName("catalogue-wrap")[0].clientWidth;
      this.linkShowposition.top =
        top -
        document.getElementsByClassName("edit-top-wrap")[0].clientHeight -
        60;
    },
    //初始化富文本内容
    setContent() {
      this.documentInfo.content
        ? this.quill.setContents(JSON.parse(this.documentInfo.content), "api")
        : "";
      this.$nextTick(() => {
        this.quill.setSelection(null);
      });
    },
    // 发送评论内@人
    sendAt(data1) {
      const param = {
        projectId: this.get_pid(),
        documentId: this.documentInfo.documentId,
        data: data1,
      };
      sendAt(param).then((res) => {});
    },
    saveContent() {
      const self = this;
      if (self.timer) {
        clearTimeout(self.timer);
      }
      self.timer = setTimeout(() => {
        const content = JSON.stringify(self.quill.getContents().ops);
        const params = {
          projectId: self.get_pid(),
          documentId: self.documentInfo.documentId,
          data: {
            content: content,
          },
        };
        editDocument(params).then((res) => {
          self.timer = null;
          self.$emit("contentChange");
        });
      }, 500);
    },
    // 确定@的位置
    atPosition() {
      const selection = this.quill.getSelection();
      const bounds = this.quill.getBounds(selection.index, selection.length);
      if (
        bounds.top + 300 >
        document.getElementById("editor-container").clientHeight
      ) {
        bounds.top -= 230;
      }
      const scrollTop = document.getElementById(
        "editor-container-wrap"
      ).scrollTop;
      this.atShowposition = bounds;
      this.atShowposition.top = bounds.top - scrollTop + bounds.height;
      if (
        document.getElementById("editor-container-wrap").clientHeight -
          bounds.top <
        200
      ) {
        this.atShowposition.top -= bounds.height + 220;
      }
    },
    // 监听富文本的变化
    text_change(delta, oldDelta, source) {
      // 如果没有样式设置默认样式
      this.$emit("sendingFn", true);
      const ele = document.getElementById("editor-container-wrap");
      // 监听改行是否设置为标题，是则禁止改变字体大小
      if (this.quill.getFormat().valueOf("header")) {
        let temp = this.quill.getFormat();
        const toolbar = this.quill.getModule("toolbar");
        if (temp.header) {
          toolbar.container.querySelector(".ql-size").style.cursor =
            "not-allowed";
          toolbar.container.querySelector(
            ".ql-size .ql-picker-label"
          ).style.pointerEvents = "none";
        } else {
          toolbar.container.querySelector(".ql-size").style.cursor = "auto";
          toolbar.container.querySelector(
            ".ql-size .ql-picker-label"
          ).style.pointerEvents = "auto";
        }
      }
      // 实时改变工具栏中字体大小显示
      if (
        this.quill.getLeaf(this.quill.getSelection().index)[0].domNode
          .parentNode
      ) {
        const nowNodeParent = this.quill.getLeaf(
          this.quill.getSelection().index
        )[0].domNode.parentNode;
        const size = window.getComputedStyle(nowNodeParent).fontSize;
        document
          .getElementsByClassName("ql-picker-label")[1]
          .setAttribute("data-label", size);
      }
      if (ele.scrollTop !== this.wrapScrollTop) {
        ele.scrollTop = this.wrapScrollTop;
      }
      // 改版后滚动条不随之滚动修复
      if (
        this.quill.getSelection() &&
        this.quill.getLength() - 2 == this.quill.getSelection().index &&
        delta.ops[delta.ops.length - 1].insert === "\n"
      ) {
        ele.scrollTop = ele.scrollHeight;
      }
      this.lastChange = { delta, oldDelta, source };
      const self = this;
      // let atFlag = false;
      // let query = "";
      delta.ops.forEach((item) => {
        if (item.insert === "@") {
          // atFlag = true;
          this.isQuerying = true;
          this.atQuery = "";
          this.atPosition();
        } else if (this.isQuerying) {
          if (item.insert == "\n" || item.insert == " ") {
            this.isQuerying = false;
          } else if (item.insert) {
            this.atQuery += item.insert;
          } else if (item.delete) {
            if (item.delete > this.atQuery.length) {
              this.isQuerying = false;
            } else {
              this.atQuery = this.atQuery.slice(0, -item.delete);
            }
          }
        }
      });
      this.atShow = this.isQuerying;
      const selection = this.quill.getSelection();
      this.userCursorList.forEach((item) => {
        if (selection && item.index === selection.index) {
          this.$set(item, "show", false);
        } else if (selection && item.index !== selection.index) {
          this.$set(item, "show", true);
        }
      });
      const content = JSON.stringify(self.quill.getContents().ops);
      this.documentInfo.content = content;
      this.opsList.push(delta.ops);
      if (self.sendAtTimer) {
        clearTimeout(self.sendAtTimer);
        self.sendAtTimer = null;
      }
      self.sendAtTimer = setTimeout(() => {
        const ops = JSON.stringify(self.opsList);
        self.stompClient.send(
          `/app/EDIT_DOC_CONTENT/${self.get_pid()}/${
            self.documentInfo.documentId
          }`,
          {},
          JSON.stringify({
            tenantId: self.$store.state.project.tenantId,
            accountId: self.userAccountId,
            ops: ops,
            content: content,
            selection: JSON.stringify(selection),
          })
        );
        self.sendAtTime = null;
        self.opsList = [];
        self.$emit("sendingFn", false);
        self.$emit("contentChange");
      }, 2000);
      // 保存方法废弃
      // this.saveContent();
    },
    selection_change(range, oldRange, source) {
      vmson.$emit("closeTemplate", true);
      if (range) {
        this.lastRange = range;
        const bounds = this.quill.getBounds(range.index, range.length);
        const map = [
          "",
          this.$t("knowledge.Heading1"),
          this.$t("knowledge.Heading2"),
          this.$t("knowledge.Heading3"),
          this.$t("knowledge.Heading4"),
          this.$t("knowledge.Heading5"),
          this.$t("knowledge.Heading6"),
        ];
        const toolbar = this.quill.getModule("toolbar");
        toolbar.container
          ? toolbar.container
              .querySelector(".ql-header .ql-picker-label")
              .classList.add("ql-active")
          : "";
        const formats = this.quill.getFormat(range.index, range.length);
        const headerLevel =
          formats.header && formats.header.header ? formats.header.header : "";
        if (headerLevel) {
          toolbar.container.querySelector(".ql-size").style.cursor =
            "not-allowed";
          toolbar.container.querySelector(
            ".ql-size .ql-picker-label"
          ).style.pointerEvents = "none";
          // ------
          toolbar.container.querySelector(".ql-header .ql-picker-label")
            ? (toolbar.container.querySelector(
                ".ql-header .ql-picker-label"
              ).attributes["data-label"].value = map[headerLevel])
            : "";
        } else {
          toolbar.container.querySelector(".ql-size").style.cursor = "auto";
          toolbar.container.querySelector(
            ".ql-size .ql-picker-label"
          ).style.pointerEvents = "auto";
          // ------
          toolbar.container.querySelector(".ql-header .ql-picker-label")
            ? (toolbar.container.querySelector(
                ".ql-header .ql-picker-label"
              ).attributes["data-label"].value = this.$t("knowledge.body"))
            : "";
        }
        // 点击某一行时改变工具栏中字体大小显示
        if (this.quill.getLeaf(range.index)[0].domNode.parentNode) {
          const size = window.getComputedStyle(
            this.quill.getLeaf(range.index)[0].domNode.parentNode
          ).fontSize;
          document
            .getElementsByClassName("ql-picker-label")[1]
            .setAttribute("data-label", size);
        }
        // 选中表格时候限制所有块级元素
        const disableList = [
          "ql-highlight",
          "ql-header",
          "ql-code-block",
          "ql-list1",
          "ql-list2",
          "ql-video",
          "ql-better-table",
        ];
        const linkDisableList = ["ql-drawio"];
        if (formats["table-cell-line"]) {
          disableList.forEach((item) => {
            document.getElementById(item)
              ? document.getElementById(item).classList.add("disable-ql-option")
              : "";
          });
          linkDisableList.forEach((item) => {
            document.getElementById(item)
              ? document.getElementById(item).classList.add("disable-ql-option")
              : "";
          });
        } else if (formats["link"]) {
          linkDisableList.forEach((item) => {
            document.getElementById(item)
              ? document.getElementById(item).classList.add("disable-ql-option")
              : "";
          });
          disableList.forEach((item) => {
            document.getElementById(item)
              ? document
                  .getElementById(item)
                  .classList.remove("disable-ql-option")
              : "";
          });
        } else {
          disableList.forEach((item) => {
            document.getElementById(item)
              ? document
                  .getElementById(item)
                  .classList.remove("disable-ql-option")
              : "";
          });
          linkDisableList.forEach((item) => {
            document.getElementById(item)
              ? document
                  .getElementById(item)
                  .classList.remove("disable-ql-option")
              : "";
          });
        }
      }
    },
    postVersion() {
      if (this.lastChange && this.lastChange.delta) {
        const { delta, oldDelta, source } = this.lastChange;
        this.text_change(delta, oldDelta, source);
      }

      const params = {
        projectId: this.get_pid(),
        documentId: this.documentInfo.documentId,
      };
      params.content = this.documentInfo.content;
      postDocumentVersion(params).then((res) => {
        Message.success(this.$t("version.message.publishSuccess"));
        this.$emit("saveVersionStatus", true);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.edit-wrap {
  width: 68%;
  height: 100%;
  position: relative;
  text-align: left;
  padding: 10px;
  flex-grow: 1;

  #menu {
    display: none;
    position: absolute;
    width: 130px;
    height: fit-content;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
    background: #fff;
    border-radius: 8px;
    overflow: hidden;

    div {
      div {
        height: 40px;
        line-height: 40px;
        color: rgba(48, 100, 143, 1);
        font-size: 12px;
        text-align: center;
        cursor: default;

        &:hover {
          background: rgba(229, 229, 229, 0.61);
          cursor: pointer;
        }
      }
    }
  }

  .at_position {
    position: absolute;
  }

  /*no*/
  & > div {
    height: 100%;
  }

  .ql-toolbar {
    /*no*/
    border: none;
  }

  .ql-container {
    height: calc(100% - 40px);
    /*no*/
    border: none;
    // overflow-y: scroll;

    //::v-deep .ql-editor {
    //  overflow-y: visible;
    //}
    //
    &::-webkit-scrollbar {
      width: 10px;
      height: 8px;
      background-color: #ebeef5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(207, 207, 207, 1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: rgba(255, 255, 255, 1);
    }
  }

  .ql-formats {
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      width: 1px;
      height: 100%;
      position: absolute;
      right: -10px;
      background-color: rgba(204, 204, 204, 1);
    }
  }

  .cursor_span {
    position: absolute;
    display: inline-block;
    width: 2px;
    height: 20px;
    left: 0;
    top: 0;

    & > i {
      display: inline-block;
      position: absolute;
      width: 8px;
      height: 8px;
      top: 0;
      left: -3px;
      border-radius: 3px;
      cursor: pointer;
    }

    & > span {
      position: absolute;
      display: inline-block;
      background-color: rgba(225, 225, 225, 1);
      top: -20px;
      left: 4px;
      font-size: 16px;
      width: max-content;
      padding: 4px;
      border-radius: 4px;
      height: max-content;
      line-height: 20px;
    }
  }

  .twinkle {
    animation: twinkle 1s infinite;
  }

  @keyframes twinkle {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.link_position {
  position: absolute;
  height: auto !important;
}
.edit_link_position {
  position: absolute;
  height: auto !important;
  padding: 20px;
  border-radius: 2px;
  background: white;
  width: 450px;
  box-shadow: 2px 2px 4px 2px #c2c1c1;
  .edit_link_position_row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span {
      font-size: 16px;
      color: #606266;
      width: 50px;
    }
  }
}
.link {
  padding: 0 0px 0 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 50px;
  background: white;
  box-shadow: 2px 2px 4px 2px #c2c1c1;
  .link_link {
    max-width: 250px;
    line-height: 40px;
    margin: 0 5px;
    color: #606266;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .link_edit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .link_remove {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .link_choose {
    margin: 0 5px;
    padding-left: 5px;
    border-left: 2px solid #e5e5e5;
    // border-right: 2px solid #e5e5e5;
  }
  .link_edit:hover {
    background: rgb(240, 242, 245);
    border-radius: 4px;
  }
  .link_remove:hover {
    background: rgb(240, 242, 245);
    border-radius: 4px;
  }
  ::v-deep .link-button:hover {
    background: rgb(240, 242, 245) !important;
    color: #606266 !important;

    border-radius: 4px;
  }
}
</style>
<style lang="scss">
.active-card {
  border-color: #2a82e4 !important;
}

.knowledge-selected-Line {
  background-color: #f0f7ff;
}
</style>
