<template>
  <div id="history-home-wrap" class="home-wrap">
    <div v-if="!knowledge_edit" class="edit-wrap">
      <div
        v-if="documentId && authList.length"
        style="display: flex; width: 100%; height: 100%; overflow-x: hidden"
      >
        <historyInfo
          :checked-node="checkedNode"
          :title="title"
          :draggable="draggable"
          :auth-list="authList"
          :catalogue-data="catalogueData"
          :right-comment-list="rightCommentList"
          :content-doc="contentDoc"
          :doc-histories="docHistories"
          :document-id="documentId"
          :created-by="createdBy"
          @getAuth="getAuthFn"
          @getCurrDocument="getCurrDocument"
          @updateMenu="getDocuments"
          @getComments="getComments"
          @filterRight="filterRight"
          @deleteDoc="deleteDoc"
        ></historyInfo>
      </div>
      <div v-else-if="documentId == null" class="no-pr">
        <img src="@/assets/img/docNoSelect.png" alt="" />
        <p>{{ $t("knowledge.NoPagesSelected") }}</p>
      </div>
      <div v-else-if="!authList.length" class="no-pr">
        <img src="@/assets/img/nothing.png" alt="" />
        <p>{{ $t("knowledge.SorryNoPermission") }}</p>
        <el-button type="primary" @click="apply">{{
          $t("knowledge.RequestPermission")
        }}</el-button>
      </div>
    </div>
    <div v-else style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  getDocuments,
  getAuth,
  getDocument,
  getContent,
  getDocumentHistories,
} from "@/network/knowledge";
import { apply_access } from "@/network/knowledge";
import Title from "./title.vue";
import historyInfo from "./historyInfo.vue";

export default {
  components: {
    Title,
    historyInfo,
  },
  data() {
    return {
      menuList: [],
      checkedNode: null,
      draggable: true,
      authList: [],
      catalogueData: [],
      rightCommentList: [],
      documentId: this.$route.query.documentId,
      title: this.$route.query.title,
      contentDoc: null,
      docHistories: null,
      createdBy: "",
    };
  },
  created() {
    this.documentId = this.$route.query.documentId;
    this.title = this.$route.query.title;
  },
  computed: {
    ...mapGetters(["knowledge_edit"]),
  },
  mounted() {
    // 获取所有目录
    this.SET_KNOWLEDGE_EDIT({
      knowledge_edit: false,
      knowledge_edit_mode: "edit",
    });
    // this.getDocuments();
    this.getCurrDocument();
    this.getHistories();
    this.getAuthFn();
  },
  updated() {
    // 这里要写在updated函数里面，要不然有问题，不知道为什么
  },
  methods: {
    ...mapMutations(["SET_KNOWLEDGE_EDIT"]),
    async apply() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.documentId,
      };
      await apply_access(params).then(() => {
        this.$message.success(this.$t("knowledge.ViewAccessRequested"));
      });
    },
    // 收起展开折叠事件
    filterRight(id) {
      this.$refs.rightComment.filterRight(id);
    },
    getComments() {
      this.$refs.rightComment.getComments();
    },
    // 更新文章大纲
    catalogueUpdate(params) {
      this.catalogueData = params;
    },
    // 右侧评论
    commentinline(list) {
      this.rightCommentList = list;
    },
    draggableFn() {
      this.draggable = false;
    },
    checked(node) {
      this.authList = [];
      this.checkedNode = node;
      node ? this.getSetting() : "";
    },
    getSetting() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.documentId,
      };
      getAuth(params).then((res) => {
        this.authList = res;
      });
    },
    getCurrDocument(callBack) {
      const params = {
        projectId: this.get_pid(),
        documentId: this.$route.query.documentId,
      };
      getContent(params).then((res) => {
        this.contentDoc = res;
        this.createdBy = this.matchUserNickname(res.createdBy, "");
        this.$nextTick(() => {
          callBack ? callBack() : "";
          this.$emit("hideLoading");
        });
      });
    },
    getHistories() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.$route.query.documentId,
      };
      getDocumentHistories(params).then((res) => {
        this.docHistories = res;
      });
    },
    getAuthFn() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.documentId,
      };
      getAuth(params).then((res) => {
        this.authList = res;
      });
    },
    getDocuments(callBack) {
      const params = {
        projectId: this.get_pid(),
      };
      getDocuments(params).then((res) => {
        this.menuList = res;
        this.$nextTick(() => {
          callBack ? callBack() : "";
          this.$emit("hideLoading");
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
/*拖拽区div样式*/
.resize {
  cursor: e-resize;
  min-width: 6px; /*no*/
  font-size: 32px;
  user-select: none;
}

.resize.active {
  background-color: #0090f1;
}

.resize:hover {
  background-color: #0090f1;
}

.home-wrap {
  display: flex;
  justify-content: left;
  width: 100%;
  height: 100%;

  .left-wrap {
    height: 100%;
    width: 300px;
    min-width: 300px;
  }

  .edit-wrap {
    height: 100%;
    //flex: 1 1 auto;
    flex: 1;
    overflow-y: hidden;

    .no-pr {
      width: 100%;
      height: 100%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      & > p {
        font-size: 20px;
        color: rgba(128, 128, 128, 1);
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 8px;
      background-color: #ebeef5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(207, 207, 207, 1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: rgba(255, 255, 255, 1);
    }
  }
}
</style>
