<template>
  <div class="main-wrap" v-if="contentDoc">
    <Title
      :title-text="title"
      :creator="createdBy"
      :updated-at="contentDoc.updatedAt"
      @recoverVersion="handleVersionRecover"
    ></Title>

    <div id="not-edit-wrap" class="not-edit-wrap">
      <div class="catelogue-container">
        <Catalogue
          :doc-histories="docHistories"
          @catalogueUpdate="catalogueUpdate"
          @indexSelected="handleIndexSelected"
          @selectedIndexes="handleIndexes"
        ></Catalogue>
      </div>
      <div class="document-wrap">
        <div class="document-container">
          <Document
            ref="document"
            :checked-node="checkedNode"
            :right-comment-list="rightCommentList"
            :document-id="documentId"
            :selected-index="selectedIndex"
            :doc-histories="docHistories"
            :selected-indexes="selectedIndexes"
            @titleMindChange="titleMindChange"
            @getComments="getComments"
            @filterRight="filterRight"
            @exportHtml="exportHtml"
          ></Document>
        </div>
      </div>
      <!-- <right-comment
        ref="rightComment"
        :checked-node="checkedNode"
        @commentinline="commentinline"
      ></right-comment> -->
    </div>
  </div>
</template>

<script>
import Title from "./title.vue";

import Catalogue from "./catalogue.vue";
import Document from "./document.vue";
import { text } from "body-parser";
import { recoverDocumentVersion, getDocument } from "@/network/knowledge";
export default {
  components: {
    Title,

    Catalogue,
    Document,
  },
  props: {
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
    authList: {
      type: Array,
      default() {
        return [];
      },
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    rightCommentList: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: "",
    },
    contentDoc: {
      type: Object,
      default() {
        return null;
      },
    },
    docHistories: {
      type: Array,
      default() {
        return [];
      },
    },
    documentId: {
      type: String,
      default: "",
    },
    createdBy: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editor: null,
      titleMindData: [],
      html: "",
      catalogueData: [],
      selectedIndex: 0,
      selectedIndexes: [],
    };
  },
  mounted() {},
  watch: {
    contentDoc(newContentDoc) {},
  },
  beforeDestroy() {},
  methods: {
    // 传递导出数据
    exportHtml(html) {
      this.html = html;
    },
    // 定位某行
    positionLine(lineId) {},
    filterRight(id) {
      this.$emit("filterRight", id);
    },
    getComments() {
      this.$emit("getComments");
    },
    catalogueUpdate(params) {
      this.catalogueData = params;
    },
    updateMindData() {
      this.$refs.document.getMindData();
    },
    titleMindChange(params) {
      this.titleMindData = params;
    },
    handleIndexSelected(index) {
      this.selectedIndex = index;
    },
    // 恢复页面（历史版本）
    handleVersionRecover() {
      const params = {
        documentId: this.documentId,
        projectId: this.get_pid(),
        versionId: this.docHistories[this.selectedIndex].versionId,
      };
      recoverDocumentVersion(params).then((res) => {});
    },
    handleIndexes(indexes) {
      this.selectedIndexes = indexes;
    },
  },
};
</script>

<style scoped lang="scss">
.main-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-grow: 1;
  overflow-y: hidden;
  .not-edit-wrap {
    display: flex;
    justify-content: space-between;
    height: auto;
    flex: 1 1 auto;
  }

  .catelogue-container {
    max-width: 457px;
    height: auto;
  }
  .document-wrap {
    padding-left: 141px;
    padding-right: 141px;
    padding-top: 106px;
    padding-bottom: 106px;
    width: 100%;
    opacity: 1;
    background: rgba(229, 229, 229, 0.19);
    overflow-y: scroll;
    max-height: calc(100vh - 212px);
  }
  .document-container {
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);

    border: 1px solid rgba(229, 229, 229, 1);
  }
}
</style>
