<template>
  <div class="edit-top-wrap">
    <div class="info-wrap">
      <el-tooltip
        v-if="checkedNode"
        v-ak-tooltip-auto-show
        effect="dark"
        placement="bottom"
        :content="checkedNode ? checkedNode.label : ''"
      >
        <div class="title" @dblclick="dbClick">
          <span v-show="!is_edit">
            {{ checkedNode ? checkedNode.label : "" }}</span
          >
          <el-input
            v-show="is_edit"
            ref="myInput"
            v-model="checkedNode.label"
            type="text"
            resize="none"
            @blur="titleBlur"
          ></el-input>
        </div>
      </el-tooltip>
      <!-- <div class="sub">
        <span
          >{{ $t("knowledge.Creator") }}:
          {{
            documentInfo ? matchUserNickname(documentInfo.createdBy, "") : ""
          }}
        </span>
        <span
          >{{ $t("knowledge.LatestVersion") }}：{{
            documentInfo
              ? new Date(documentInfo.updatedAt).Format("yyyy-MM-dd hh:mm:ss")
              : ""
          }}</span
        >
      </div> -->
    </div>
    <div class="tool-wrap">
      <div style="display: flex">
        <div
          v-if="userListNow.length > 5"
          style="font-size: 20px; margin-right: 10px; line-height: 35px"
        >
          ...
        </div>
        <div>
          <personal-avatar
            :avatar="matchUserInfo(userAccountId).avatar"
            :colorid="userAccountId"
            :nickname="matchUserInfo(userAccountId).nickname"
            :size="40"
            placement="top"
            style="margin-right: 5px"
          ></personal-avatar>
        </div>
        <div>
          <personal-avatar
            v-for="(item, index) in userListNow.slice(0, 5)"
            :key="index"
            :avatar="item.userName.indexOf('inter') == -1 ? item.avatar : ''"
            :colorid="item.userAccountId"
            :nickname="item.userName"
            :size="40"
            placement="top"
            style="margin-right: 5px"
          ></personal-avatar>
        </div>
      </div>
      <div style="float: right">
        <el-button
          style="margin-right: 20px; margin-left: 10px"
          :disabled="sending"
          @click="notEditFn"
        >
          {{ $t("knowledge.Exit") }}
        </el-button>
        <el-button
          type="primary"
          style="margin-left: 0px"
          :disabled="sending"
          @click="saveAsVersion"
        >
          {{ $t("nodeDetail.publish.title") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import PersonalAvatar from "@/components/personal";
import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
import { updateTitle, getContent } from "@/network/knowledge";
import { Message } from "element-ui";
import { exitEdit } from "../../../../network/knowledge";
export default {
  components: {
    PersonalAvatar,
  },
  props: {
    sending: {
      type: Boolean,
      default: false,
    },
    documentInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    checkedNode: {
      type: Object,
      default() {
        return undefined;
      },
    },
    userListNow: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      is_edit: false,
    };
  },
  computed: {
    ...mapGetters(["user_list", "userAccountId", "knowledge_edit_mode"]),
  },
  mounted() {},
  methods: {
    ...mapMutations(["SET_KNOWLEDGE_EDIT"]),
    // 控制聚焦名称输入
    focusTitle() {
      if (this.knowledge_edit_mode === "add") {
        this.is_edit = true;
        this.$nextTick(() => {
          // 输入框聚焦
          this.$refs.myInput.focus();
          this.$vmson.$emit("closeTemplate", false);
          // 这里要设置延迟，如果不设置延迟，下一次dom渲染会取消全选操作
          setTimeout(() => {
            // 文字全选
            this.$refs.myInput.select();
          }, 1500);
        });
      } else {
        this.is_edit = false;
      }
    },
    dbClick() {
      this.is_edit = true;
      this.$nextTick(() => {
        // 输入框聚焦
        this.$refs.myInput.focus();
      });
    },
    titleBlur() {
      if (this.checkedNode.label === "") {
        Message.error(this.$t("knowledge.articleTitle"));
        return;
      }
      this.is_edit = false;
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
        data: {
          title: this.checkedNode.label,
        },
      };
      updateTitle(params).then(() => {});
    },
    notEditFn() {
      const oldUpdatedAt = this.documentInfo.updatedAt;
      if (this.checkedNode.label === "") {
        return;
      }
      const imgList = document.querySelectorAll("#editor-container img");
      let flag = false;

      imgList.forEach((item) => {
        if (item.currentSrc.indexOf("/img/") !== -1) {
          flag = true;
        }
      });
      if (flag) {
        this.$confirm(this.$t("knowledge.sureExit"), this.$t("review.tip11"), {
          confirmButtonText: this.$t("btn.confirmBtn"),
          cancelButtonText: this.$t("btn.cancelBtn"),
          type: "warning",
        })
          .then(() => {
            this.$emit("updateMenu", () => {
              const params = {
                projectId: this.get_pid(),
                documentId: this.checkedNode ? this.checkedNode.documentId : "",
              };
              getContent(params).then((res) => {
                let isUpdate;
                if (res.updatedAt === oldUpdatedAt) {
                  isUpdate = false;
                } else {
                  isUpdate = true;
                }
                const params = {
                  projectId: this.get_pid(),
                  documentId: this.checkedNode
                    ? this.checkedNode.documentId
                    : "",
                  isUpdated: isUpdate,
                };
                exitEdit(params).then((res) => {});
              });
              this.SET_KNOWLEDGE_EDIT({
                knowledge_edit: false,
                knowledge_edit_mode: "edit",
              });
            });
          })
          .catch(() => {});
      } else {
        this.$emit("updateMenu", () => {
          const params = {
            projectId: this.get_pid(),
            documentId: this.checkedNode ? this.checkedNode.documentId : "",
          };
          getContent(params).then((res) => {
            let isUpdate;
            if (res.updatedAt === oldUpdatedAt) {
              isUpdate = false;
            } else {
              isUpdate = true;
            }
            const params = {
              projectId: this.get_pid(),
              documentId: this.checkedNode ? this.checkedNode.documentId : "",
              isUpdated: isUpdate,
            };
            exitEdit(params).then((res) => {});
          });
          this.SET_KNOWLEDGE_EDIT({
            knowledge_edit: false,
            knowledge_edit_mode: "edit",
          });
        });
      }
    },
    saveAsVersion() {
      if (this.checkedNode.label === "") {
        return;
      }
      this.$emit("saveAsVersion", true);
      this.notEditFn();
    },
  },
};
</script>

<style scoped lang="scss">
.edit-top-wrap {
  width: 100%;
  height: 100px;
  padding: 20px 90px;
  box-sizing: border-box;
  border-bottom: 2px solid rgba(229, 229, 229, 1);
  text-align: left;
  display: flex;

  .info-wrap {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > .title {
      font-size: 36px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & > .sub {
      font-size: 16px;
      font-weight: 400;
      color: rgba(166, 166, 166, 1);

      & > span {
        margin-right: 60px;
      }
    }
  }

  .tool-wrap {
    width: 40%;
    display: flex;
    align-items: center;
    padding-left: 88px;
    justify-content: flex-end;
  }
}
</style>
