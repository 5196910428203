<template>
  <div v-if="!isCollapse" class="catalogue-wrap">
    <p class="title">{{ $t("knowledge.HistoricalVersions") }}</p>
    <div class="button-group">
      <el-button
        class="underline-button"
        :class="{ 'button-active': activeButton === 'search' }"
        type="text"
        @click="handleSearchSwitch"
      >
        {{ $t("btn.view") }}
      </el-button>
      <el-button
        class="underline-button"
        :class="{ 'button-active': activeButton === 'compare' }"
        type="text"
        @click="activeButton = 'compare'"
      >
        {{ $t("knowledge.VersionComparison") }}
      </el-button>
    </div>
    <div v-if="docHistories" class="his-container">
      <el-button
        v-for="(version, index) in docHistories"
        :key="index"
        class="button-item"
        :class="{ selected: selectedIndex === index }"
        @click="handleButtonClick(index)"
      >
        <el-checkbox
          v-if="activeButton === 'compare'"
          class="checkbox"
          size="medium"
          :checked="selectedIndexes.includes(index)"
          :disabled="
            !selectedIndexes.includes(index) && selectedIndexes.length == 2
          "
          @change="handleCheckboxChange(index)"
        ></el-checkbox>
        <div class="text-container">
          <div class="primary-text" v-if="version.published">
            {{ formatDateTime(version.updatedAt) }}
          </div>
          <div class="primary-text" v-if="!version.published">
            {{ $t("knowledge.CurrentVersion") }}
          </div>
          <div class="secondary-text">
            {{ $t("knowledge.LastEditedBy") }}：{{
              formatIdToName(version.updatedBy)
            }}
          </div>
        </div>
      </el-button>
    </div>
  </div>
</template>

<script>
import { getContent } from "@/network/knowledge";

export default {
  props: {
    documentInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
    docHistories: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isCollapse: false,
      lastcheckid: "",
      activeButton: "search",
      selectedIndex: 0, // Default to first button selected
      selectedIndexes: [],
    };
  },
  watch: {},
  mounted() {},
  methods: {
    nodeClick(node) {
      this.lastcheckid && document.getElementById(this.lastcheckid)
        ? document
            .getElementById(this.lastcheckid)
            .classList.remove("checked_catalogue")
        : "";
      document.getElementById(node.type.id).classList.add("checked_catalogue");
      const rollDom = document.getElementById(node.type.id);
      const warp =
        document.getElementById("no-hidden-dialog") ||
        document
          .getElementById("editor-container")
          .getElementsByClassName("ql-editor")[0];
      warp.scrollTop = rollDom.offsetTop;
      this.lastcheckid = node.type.id;
    },
    getContent(id) {
      if (id) {
        const params = {
          projectId: this.get_pid(),
          documentId: id,
        };
        getContent(params).then((res) => {
          if (res.content) {
            const delta = JSON.parse(res.content);
            // 创建Worker
            const worker = new Worker("/js/worker/knowledgeLeftMenuTree.js");
            // 发送消息
            worker.postMessage(delta);
            const self = this;
            worker.addEventListener("message", function (e) {
              const tree = e.data.tree;
              self.data = tree;
              self.$emit("catalogueUpdate", tree);
            });
          } else {
            this.data = [];
          }
        });
      } else {
        this.data = [];
      }
    },
    formatDateTime(date) {
      const dt = new Date(date);
      const year = dt.getFullYear();
      const month = (dt.getMonth() + 1).toString().padStart(2, "0");
      const day = dt.getDate().toString().padStart(2, "0");
      const hours = dt.getHours().toString().padStart(2, "0");
      const minutes = dt.getMinutes().toString().padStart(2, "0");
      return `${year}/${month}/${day} ${hours}:${minutes}`;
    },

    formatIdToName(id) {
      return this.matchUserNickname(id, "");
    },
    handleButtonClick(index) {
      this.selectedIndex = index;
      this.$nextTick(() => {
        this.$emit("indexSelected", index);
      });
    },
    handleCheckboxChange(index) {
      if (this.selectedIndexes.includes(index)) {
        this.selectedIndexes = this.selectedIndexes.filter((i) => i !== index);
      } else if (this.selectedIndexes.length < 2) {
        this.selectedIndexes.push(index);
      }
      this.$nextTick(() => {
        this.$emit("selectedIndexes", this.selectedIndexes);
      });
    },
    handleSearchSwitch() {
      this.activeButton = "search";
      this.selectedIndexes = [];
      this.$nextTick(() => {
        this.$emit("selectedIndexes", this.selectedIndexes);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.catalogue-wrap {
  width: 446px;
  height: 100%;
  border-right: 2px solid rgba(204, 204, 204, 1);
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
  p {
    padding: 0;
    margin: 0;
    background-color: transparent;
  }
  .tree-wrap {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 10px;
      height: 8px;
      background-color: #ebeef5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(207, 207, 207, 1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: rgba(255, 255, 255, 1);
    }
  }

  .title {
    margin-left: 63px;
    margin-top: 33px;
    margin-bottom: 14px;
    // top: 288px;
    width: 234px;
    height: 61px;
    opacity: 1;
    /** 文本1 */
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 52.13px;
    color: rgba(56, 56, 56, 1);
    text-align: left;
    vertical-align: top !important;
  }
  .button-group {
    display: flex;
    background-color: transparent;
  }
  .his-container {
    overflow-y: scroll;
    max-height: calc(100vh - 380px);
  }
  .underline-button {
    flex: 1;
    height: 46px;
    color: rgba(128, 128, 128, 1);
    border-bottom: 4px solid rgba(56, 56, 56, 0);
    border-radius: 2px;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }

  .underline-button:hover {
    color: rgba(56, 56, 56, 1);
    border-bottom: 4px solid rgba(56, 56, 56, 1);
    border-radius: 2px;
    background-color: transparent !important;
  }

  .button-active {
    color: rgba(56, 56, 56, 1);
    border-bottom: 4px solid rgba(56, 56, 56, 1);
    border-radius: 2px;
    background-color: transparent !important;
  }
  .button-item {
    position: relative;
    width: 100%;
    padding: 0;
    height: 69px;
    text-align: left;
    border: none;
    align-items: start;
    margin: 0;
    opacity: 1;
    background: rgba(255, 255, 255, 0.78);
    border: 1px solid rgba(255, 255, 255, 0.78);
  }

  .button-item .primary-text {
    margin-left: 55px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    height: 29px;
    opacity: 1;
    /** 文本1 */
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 28.96px;
    color: rgba(56, 56, 56, 1);
    text-align: left;
    vertical-align: top;
  }

  .button-item .secondary-text {
    margin-top: 21px;
    margin-bottom: 21px;
    margin-left: auto;
    margin-right: 10px;
    height: 27px;
    opacity: 1;
    /** 文本1 */
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(128, 128, 128, 1);
    text-align: left;
    vertical-align: top;
    overflow: hidden;
  }
  .text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; /* Adjust based on your needs */
    width: 100%; /* Adjust based on your needs */
    margin: 0;
    padding: 0;
  }

  .button-item.selected {
    opacity: 1;
    background: rgba(229, 229, 229, 0.55);
  }

  .checkbox {
    position: absolute;
    margin-left: 20px;
    margin-top: 27px;
    transform: scale(1.4);
  }

  ::v-deep {
    .el-tree--highlight-current
      .el-tree-node.is-current
      > .el-tree-node__content {
      color: rgba(42, 130, 228, 1);
      background-color: #f0f7ff !important;
    }

    .el-tree-node:focus > .el-tree-node__content {
      background-color: transparent;
    }

    .el-tree-node__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.left-menu-hide {
  width: 20px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  text-align: center;

  &:hover {
    background-color: #f0f7ff;
    cursor: pointer;
  }
}
</style>
<style>
.checked_catalogue {
  background-color: #f0f7ff;
}
</style>
