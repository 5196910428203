<template>
  <div v-show="loadingquill" v-loading="loading">
    <div class="edit-wrap" @click.prevent="clickeWrap">
      <div id="standalone-container-right">
        <div
          :id="'toolbar-container-right-comment' + parentCommentId"
          style="display: none"
        ></div>
        <div
          :id="'editor-container-right-comment' + parentCommentId"
          class="editor-container-right-comment"
        ></div>
      </div>
      <div
        v-if="atShow"
        class="at_position"
        :style="{
          left: '40px',
          top: atShowposition.top + 20 + 'px',
        }"
      >
        <at-vue ref="atVue" @selectPerson="selectPerson" :queryString="atQuery"></at-vue>
      </div>
    </div>
    <div class="edit-button">
      <el-button v-if="repeat" type="info" @click.stop="cancelComment"
        >{{$t("btn.cancelBtn")}}
      </el-button>
      <el-button type="primary" @click.stop="sendComment">{{ $t("addNodeDetail.comment") }} </el-button>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import { ossAuthorization } from "@/utils/oss";
import AtVue from "../edit/At.vue";
import { sendComments } from "@/network/knowledge";
export default {
  components: {
    AtVue,
  },
  props: {
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
    // 父评论id
    parentCommentId: {
      type: String,
      default: "",
    },
    repeat: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loadingquill: false,
      loading: false,
      atShow: false,
      atShowposition: {
        left: 0,
        top: 0,
      },
      atQuery: "",
      isQuerying: false,
    };
  },
  async mounted() {
    await ossAuthorization.call(this);
    this.initQuill();
  },
  methods: {
    // 关闭@
    clickeWrap() {
      this.atShow = false;
      this.isQuerying = false;
    },
    // 选择用户
    selectPerson(item) {
      const index = this.quill.getSelection().index - this.atQuery.length;
      this.atShow = false;
      this.isQuerying = false;
      this.quill.deleteText(index - 1, 1 + this.atQuery.length);
      this.quill.insertEmbed(index - 1, "my-span", {
        name: "@" + item.nickname,
        accountId: item.accountId,
      });
      this.quill.insertEmbed(index + item.nickname.length, "text", " ");
      setTimeout(
        () =>
          this.quill.setSelection(
            this.quill.getSelection().index + item.nickname.length + 2,
            0
          ),
        100
      );
    },
    // 取消评论
    cancelComment() {
      this.info.showRepeat = false;
    },
    initQuill() {
      this.quill = new Quill(
        `#editor-container-right-comment${this.parentCommentId}`,
        {
          modules: {
            toolbar: false,
            history: {
              delay: 2000,
              maxStack: 500,
            },
            ImageResize: {
              modules: ["Resize", "DisplaySize"],
              displayStyles: {
                backgroundColor: "black",
                border: "none",
                color: "white",
              },
            },
          },
          theme: "snow",
        }
      );
      this.quill.root.addEventListener("paste", this.pasteFile, true);
      this.quill.on("text-change", this.text_change);
      this.loadingquill = true;
    },
    // 确定@的位置
    atPosition() {
      const selection = this.quill.getSelection();
      const bounds = this.quill.getBounds(selection.index, selection.length);
      this.atShowposition = bounds;
    },
    // 监听富文本的变化
    text_change(delta, oldDelta, source) {
      delta.ops.forEach((item) => {
        if (item.insert === "@") {
          // atFlag = true;
          this.isQuerying = true;
          this.atQuery = "";
          this.atPosition();
        } else if (this.isQuerying) {
          if (item.insert == "\n" || item.insert == " "){
            this.isQuerying = false;
          }
          else if (item.insert) {
            this.atQuery += item.insert;
          } 
          else if (item.delete) {
            if (item.delete > this.atQuery.length) {
              this.isQuerying = false;
            } 
            else {
              this.atQuery = this.atQuery.slice(0, -item.delete); 
            }
          }
        }
      });
      this.atShow = this.isQuerying;
    },
    // 粘贴图片
    pasteFile(evt) {
      // 获取粘贴的html 和text 并判断是否包含img
      const html = evt.clipboardData.getData("text/html");
      const hasImg = /<img\b[^>]*>/.test(html);
      const text = evt.clipboardData.getData("text/plain");
      if (!hasImg) {
        // 判断是否是网络链接
        let re = new RegExp("^(http|https)://", "i");
        const isHttp = re.test(text);
        const index = this.quill.getSelection().index;
        if (isHttp) {
          evt.preventDefault();
          this.quill.insertEmbed(index, "link", {
            href: text,
            target: "_blank",
            innerText: text,
          });
          this.quill.setSelection(index + text.length + 1);
        } else {
          return true;
        }
      }
    },
    // 发送评论
    sendComment() {
      if (this.quill.getContents().ops.length > 1) {
        const plainText = this.quill.getText(0);
        const content = JSON.stringify(this.quill.getContents().ops);
        const atList = [];
        const type = "line";
        const parentId = this.parentCommentId;
        this.quill.getContents().ops.forEach((item) => {
          if (item.attributes && item.attributes["my-span"]) {
            atList.push(item.attributes["my-span"].accountId);
          }
        });
        sendComments({
          projectId: this.get_pid(),
          documentId: this.checkedNode.documentId,
          data: {
            plainText,
            content,
            type,
            atList,
            parentId,
          },
        }).then((res) => {
          this.quill.setContents([]);
          this.info.showRepeat = false;
          this.$emit("getComments");
        });
      } else {
        this.$message({
          type: "warning",
          message: this.$t("knowledge.CommentNotEmpty"),
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-wrap {
  width: 100%;
  /*no*/
  min-height: 100px;
  /*no*/
  position: relative;
  .at_position {
    position: absolute;
  }
}
.editor-container-right-comment {
  border-top: 1px solid #ccc !important;
}
.edit-button {
  margin-top: 20px;
  text-align: right;
}
</style>
