<template>
  <div ref="jsAtEditorElement" class="jsAtEditorElement">
    <div
      ref="jsDialogElement"
      v-loading="dialogLoading"
      :class="['atDialogClass']"
    >
      <el-scrollbar ref="scrollbarRef" class="scroll-height">
        <div v-if="lists.length == 0" class="persons-dialog-nothing">
          <!-- <img src="@/assets/img/nothing.png" alt="" /> -->
          <span>{{ $t("nodeDetail.activity.comment.place") }}</span>
        </div>
        <ul class="persons-dialog-lists">
          <li
            v-for="item in lists"
            v-show="!item.deleted"
            :key="item.accountId"
            :class="{ person_item: true }"
            @mousedown="selectPerson(item)"
          >
            <personal-avatar
              :avatar="item.avatar"
              :colorid="item.accountId"
              :nickname="item.name"
              :size="20"
              :tool-tip-disabled="true"
              :isProjectDropdown="true"
              style="margin-right: 5px"
            ></personal-avatar>
            <span style="font-size: 12px">{{ item.nickname }}</span>
          </li>
        </ul>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { get_allProjectMember } from "@/network/mindmap/index.js";
import PersonalAvatar from "@/components/personal";
import { pinyin } from "pinyin-pro";
export default {
  name: "At",
  components: {
    PersonalAvatar,
  },
  props: {
    placeholderText: {
      type: String,
      default() {
        return this.$t("addAt.default");
      },
    },
    queryString: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogLoading: false,
      lists: [],
      activeIndex: 0,
      preventKeyUp: false,
    };
  },
  computed: {},
  watch: {
    queryString(newVal) {
      this.initData();
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    getPinyinMatch(item, inputValue) {
      /* 输入内容拼音转换 */
      // 完整拼音
      const pyInput = pinyin(inputValue, {
        toneType: "none",
        type: "array",
      }).join("");
      // 拼音首字母
      const headerPyInput = pinyin(inputValue, {
        pattern: "first",
        type: "array",
      }).join("");
      const pyE = pinyin(item.nickname, { toneType: "none", type: "array" })
        .join("")
        .slice(0, pyInput.length);
      // 列表中每项的拼音首字母
      const headerPyE = pinyin(item.nickname, {
        pattern: "first",
        type: "array",
      })
        .join("")
        .slice(0, headerPyInput.length);
      // 过滤匹配
      return (
        item.nickname.includes(inputValue) || pyE === pyInput
        // ||
        // headerPyE === headerPyInput
      );
    },
    initData() {
      this.dialogLoading = true;
      if (this.queryString != "") {
        get_allProjectMember(this.get_pid()).then((res) => {
          this.lists = res
            .map((item) => {
              if (item.deleted !== 1) {
                return item;
              }
            })
            .filter((item) => {
              return item && this.getPinyinMatch(item, this.queryString);
            });
          this.dialogLoading = false;
          this.activeIndex = 0;
        });
      } else {
        get_allProjectMember(this.get_pid()).then((res) => {
          this.lists = res
            .map((item) => {
              if (item.deleted !== 1) {
                return item;
              }
            })
            .filter((item) => item);
          this.dialogLoading = false;
          this.activeIndex = 0;
        });
      }
    },
    selectPerson(item) {
      this.$emit("selectPerson", item);
    },
  },
};
</script>
<style lang="scss">
.jsAtEditorElement {
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
<style lang="scss" scoped>
.scroll-height {
  height: 200px;
  /*no*/
}
.jsAtEditorElement {
  display: flex;
  position: relative;
}
.jsEditorElement {
  outline: none;
  width: 100%;
  flex: none;
  word-break: break-all;
}

.jsEditorElement:empty::before {
  content: attr(data-content);
  color: rgba(166, 166, 166, 1);
  font-size: 16px;
}

.jsEditorElement:focus::before {
  content: "";
}
.persons-dialog-nothing {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }

  span {
    color: rgba(166, 166, 166, 1);
    font-size: 14px;
  }
}
.atDialogClass {
  z-index: 999;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 6px 24px 0 rgba(31, 35, 44, 0.1);
  border: 1px solid rgba(166, 166, 166, 0.5);
  border-radius: 6px;
  width: 200px;

  .persons-dialog-lists {
    list-style: none;
    padding: 20px 10px;
    margin: 0;
    .person_item {
      display: flex;
      align-items: center;
      padding: 10px 50px 5px 10px;
      margin: 2px 0;
      &::before {
        content: none;
      }
      &.active-hover {
        background-color: rgba(166, 166, 166, 0.3);
        border-radius: 4px;
      }
      &:hover {
        background-color: rgba(166, 166, 166, 0.1);
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
</style>
