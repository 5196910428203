<template>
  <div class="knowledge_wrap">
    <el-header style="height: 60px">
      <home-topbar :is-home="true" :is-personal="true"> </home-topbar>
    </el-header>
    <div style="height: calc(100% - 60px)">
      <loading v-show="is_loading"></loading>
      <history-home
        v-show="!is_loading"
        @hideLoading="hideLoading"
      ></history-home>
    </div>
  </div>
</template>

<script>
import loading from "../knowledge/components/loading.vue";
import knowledgeHome from "../knowledge/components/knowledgeHome.vue";
import historyHome from "./components/historyHome.vue";
import HomeTopbar from "@/components/homeTopbar";
import "@/plugins/quill.custom.js";
import { customHeader } from "@/plugins/quill.custom.js";
export default {
  components: {
    HomeTopbar,
    loading,
    knowledgeHome,
    historyHome,
  },
  data() {
    return {
      is_loading: true,
    };
  },
  created() {
    customHeader();
  },
  methods: {
    hideLoading() {
      this.is_loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.knowledge_wrap {
  width: 100%;
  height: 100%;
}
</style>
