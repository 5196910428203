<template>
  <div v-show="loadingquill" v-loading="loading">
    <div class="edit-wrap" @click.prevent="clickeWrap">
      <div id="standalone-container">
        <toolbar
          :quill="quill"
          :container="'toolbar-container-comment' + parentCommentId"
        ></toolbar>
        <div :id="'editor-container-comment' + parentCommentId"  @keydown="handleTextChange"></div>
      </div>
      <div
        v-if="atShow"
        class="at_position"
        :style="{
          left: atShowposition.left - 100 + 'px',
          top: atShowposition.top + 100 + 'px',
        }"
      >
        <at-vue ref="atVue" @selectPerson="selectPerson" :queryString="atQuery"></at-vue>
      </div>
    </div>
    <div class="edit-button">
      <el-button v-if="repeat" type="info" @click="cancelComment"
        >{{ $t("btn.cancelBtn") }}
      </el-button>
      <el-button type="primary" @click="sendComment"
        >{{ $t("addNodeDetail.comment") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import { ossAuthorization } from "@/utils/oss";
import AtVue from "../edit/At.vue";
import { sendComments } from "@/network/knowledge";
import toolbar from "../toolbar.vue";
import QuillBetterTable from "@/plugins/quill-better-table.js";
export default {
  components: {
    AtVue,
    toolbar,
  },
  props: {
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
    // 父评论id
    parentCommentId: {
      type: String,
      default: "",
    },
    repeat: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      quill: null,
      loadingquill: false,
      loading: false,
      atShow: false,
      atShowposition: {
        left: 0,
        top: 0,
      },
      atQuery: "",
      isQuerying: false,
    };
  },
  mounted() {
    ossAuthorization.call(this);
    this.initQuill();
  },
  inject:['wrapScrollTop'],
  methods: {
    handleTextChange(event) {
      const self = this;
      let selection = window.getSelection();
      let range = selection.getRangeAt(0);
      let position = this.quill.getSelection();
      let commonAncestor = range.commonAncestorContainer.parentElement;
      if (event.keyCode === 8) {
        if (
          commonAncestor.style.color == "rgb(64, 158, 255)" &&
          commonAncestor.className == "my-at"
        ) {
          const parentE = commonAncestor.parentNode;
          parentE.removeChild(commonAncestor);
          setTimeout(() => {
            self.quill.setSelection(
              position.index - commonAncestor.innerText.length,
              0
            );
          }, 0);
        }
      }
    },
    // 关闭@
    clickeWrap() {
      this.atShow = false;
    },
    // 选择用户
    selectPerson(item) {
      const index = this.quill.getSelection().index - this.atQuery.length;
      this.atShow = false;
      this.isQuerying = false;
      this.quill.deleteText(index - 1, 1 + this.atQuery.length);
      this.quill.insertEmbed(index - 1, "my-span", {
        name: "@" + item.nickname,
        accountId: item.accountId,
      });
      this.quill.insertEmbed(index + item.nickname.length, "text", " ");
      setTimeout(
        () =>
          this.quill.setSelection(
            this.quill.getSelection().index + item.nickname.length + 2,
            0
          ),
        100
      );
    },
    // 取消评论
    cancelComment() {
      this.info.showRepeat = false;
    },
    // 粘贴方法
    pasteFile(evt) {
      // 获取粘贴的html 和text 并判断是否包含img
      const html = evt.clipboardData.getData("text/html");
      const hasImg = /<img\b[^>]*>/.test(html);
      const text = evt.clipboardData.getData("text/plain");
      if (!hasImg) {
        // 判断是否是网络链接
        let re = new RegExp("^(http|https)://", "i");
        const isHttp = re.test(text);
        const index = this.quill.getSelection().index;
        if (isHttp) {
          evt.preventDefault();
          this.quill.insertEmbed(index, "link", {
            href: text,
            target: "_blank",
            innerText: text,
          });
          this.quill.setSelection(index + text.length + 1);
        } else {
          return true;
        }
      }
    },
    initQuill() {
      const self = this;
      this.quill = new Quill(
        `#editor-container-comment${this.parentCommentId}`,
        {
          modules: {
            toolbar: {
              container: `#toolbar-container-comment${this.parentCommentId}`,
            },
            history: {
              delay: 2000,
              maxStack: 500,
            },
            ImageResize: {
              modules: ["Resize", "DisplaySize"],
              displayStyles: {
                backgroundColor: "black",
                border: "none",
                color: "white",
              },
            },
            table: false, // disable table module
            "better-table": {
              operationMenu: {
                items: {
                  insertColumnRight: {
                    text: this.$t("knowledge.InsertRight"),
                  },
                  insertColumnLeft: {
                    text: this.$t("knowledge.InsertLeft"),
                  },
                  insertRowUp: {
                    text: this.$t("knowledge.InsertAbove"),
                  },
                  insertRowDown: {
                    text: this.$t("knowledge.InsertBelow"),
                  },
                  mergeCells: {
                    text: this.$t("knowledge.MergeCells"),
                  },
                  unmergeCells: {
                    text: this.$t("knowledge.SplitCells"),
                  },
                  deleteColumn: {
                    text: this.$t("knowledge.DeleteColumn"),
                  },
                  deleteRow: {
                    text: this.$t("knowledge.DeleteRow"),
                  },
                  deleteTable: {
                    text: this.$t("knowledge.DeleteTable"),
                  },
                },
              },
            },
            keyboard: {
              bindings: QuillBetterTable.keyboardBindings,
            },
          },
          theme: "snow",
        }
      );
      this.quill.root.addEventListener("paste", this.pasteFile, true);
      this.quill.on("text-change", this.text_change);
      // 监听光标位置
      this.quill.on("selection-change", this.selection_change);
      this.loadingquill = true;
      // 点击下方空白行 添加行
      this.quill.container.addEventListener("click", function (e) {
        const content = self.quill.getContents();
        if (
          e.target.attributes.class &&
          e.target.attributes.class.value === "ql-editor" &&
          content.ops[content.ops.length - 1] &&
          content.ops[content.ops.length - 1].insert === "\n" &&
          (!content.ops[content.ops.length - 2] ||
            (content.ops[content.ops.length - 2] &&
              content.ops[content.ops.length - 2].insert !== "\n"))
        ) {
          self.quill.insertEmbed(self.quill.getLength(), "block", true, "api");
          self.quill.setSelection(self.quill.getLength() - 1);
        }
      });
    },
    // 确定@的位置
    atPosition() {
      const selection = this.quill.getSelection();
      const bounds = this.quill.getBounds(selection.index, selection.length);
      const textBeforeAt = this.quill.getText(0, selection.index);
      if (selection.index === 0 || textBeforeAt.endsWith('\n')) {
        bounds.left += 20;
      }
      if (
        bounds.left + 200 >
        document.getElementById("editor-container-comment").clientWidth
      ) {
        bounds.left -= 120;
      } else {
        bounds.left += 100;
      }
      this.atShowposition = bounds;
    },
    // 监听富文本的变化
    text_change(delta, oldDelta, source) {
      const ele = document.getElementById(`no-hidden-dialog`);
      // 监听改行是否设置为标题，是则禁止改变字体大小
      if(this.quill.getFormat().valueOf('header')){
        let temp = this.quill.getFormat()
        const toolbar = this.quill.getModule("toolbar");
        if(temp.header){
          toolbar.container.querySelector('.ql-size').style.cursor = 'not-allowed'
          toolbar.container.querySelector('.ql-size .ql-picker-label').style.pointerEvents = 'none'
        }else{
          toolbar.container.querySelector('.ql-size').style.cursor = 'auto'
          toolbar.container.querySelector('.ql-size .ql-picker-label').style.pointerEvents = 'auto'
        }
      }
      // 实时改变工具栏中字体大小显示
      if(this.quill.getLeaf(this.quill.getSelection().index)[0].domNode.parentNode){
        const nowNodeParent = this.quill.getLeaf(this.quill.getSelection().index)[0].domNode.parentNode;
        const size = window.getComputedStyle(nowNodeParent).fontSize;
        // 区分是 常显评论 还是 评论区里的评论
        if(this.parentCommentId){
          document.getElementsByClassName('ql-picker-label')[8].setAttribute('data-label',size) 
        }else{
          document.getElementsByClassName('ql-picker-label')[1].setAttribute('data-label',size) 
        }
      }
      if (ele.scrollTop !== this.wrapScrollTop()) {
        ele.scrollTop = this.wrapScrollTop();
      }
      delta.ops.forEach((item) => {
        if (item.insert === "@") {
          // atFlag = true;
          this.isQuerying = true;
          this.atQuery = "";
          this.atPosition();
        } else if (this.isQuerying) {
          if (item.insert == "\n" || item.insert == " "){
            this.isQuerying = false;
          }
          else if (item.insert) {
            this.atQuery += item.insert;
          } 
          else if (item.delete) {
            if (item.delete > this.atQuery.length) {
              this.isQuerying = false;
            } 
            else {
              this.atQuery = this.atQuery.slice(0, -item.delete); 
            }
          }
        }
      });
      this.atShow = this.isQuerying;
    },
    selection_change(range, oldRange, source) {
      if (range) {
        const map = [
          "",
          this.$t("knowledge.Heading1"),
          this.$t("knowledge.Heading2"),
          this.$t("knowledge.Heading3"),
          this.$t("knowledge.Heading4"),
          this.$t("knowledge.Heading5"),
          this.$t("knowledge.Heading6"),
        ];
        const toolbar = this.quill.getModule("toolbar");
        toolbar.container
          ? toolbar.container
              .querySelector(".ql-header .ql-picker-label")
              .classList.add("ql-active")
          : "";
        const formats = this.quill.getFormat(range.index, range.length);
        const headerLevel =
          formats.header && formats.header.header ? formats.header.header : "";
        if (headerLevel) {
          toolbar.container.querySelector('.ql-size').style.cursor = 'not-allowed'
          toolbar.container.querySelector('.ql-size .ql-picker-label').style.pointerEvents = 'none'
          toolbar.container.querySelector(".ql-header .ql-picker-label")
            ? (toolbar.container.querySelector(
                ".ql-header .ql-picker-label"
              ).attributes["data-label"].value = map[headerLevel])
            : "";
        } else {
          toolbar.container.querySelector('.ql-size').style.cursor = 'auto'
          toolbar.container.querySelector('.ql-size .ql-picker-label').style.pointerEvents = 'auto'
          toolbar.container.querySelector(".ql-header .ql-picker-label")
            ? (toolbar.container.querySelector(
                ".ql-header .ql-picker-label"
              ).attributes["data-label"].value = this.$t("knowledge.body"))
            : "";
        }
        // 点击某一行时改变工具栏中字体大小显示
        if(this.quill.getLeaf(range.index)[0].domNode.parentNode){
          const size = window.getComputedStyle(this.quill.getLeaf(range.index)[0].domNode.parentNode).fontSize;
          // 区分是 常显评论 还是 评论区里的评论
          if(this.parentCommentId){
            document.getElementsByClassName('ql-picker-label')[8].setAttribute('data-label',size) 
          }else{
            document.getElementsByClassName('ql-picker-label')[1].setAttribute('data-label',size) 
          }
        }
        // 选中表格时候限制所有块级元素
        const disableList = [
          "ql-highlight",
          "ql-header",
          "ql-code-block",
          "ql-list1",
          "ql-list2",
          "ql-video",
          "ql-better-table",
          "ql-drawio",
        ];
        if (formats["table-cell-line"]) {
          disableList.forEach((item) => {
            document.getElementById(item).classList.add("disable-ql-option");
          });
        } else {
          disableList.forEach((item) => {
            document.getElementById(item)
              ? document
                  .getElementById(item)
                  .classList.remove("disable-ql-option")
              : "";
          });
        }
        const linkDisableList = ["ql-drawio"];
        if (formats["link"]) {
          linkDisableList.forEach((item) => {
            document.getElementById(item).classList.add("disable-ql-option");
          });
        } else {
          linkDisableList.forEach((item) => {
            document.getElementById(item)
              ? document
                  .getElementById(item)
                  .classList.remove("disable-ql-option")
              : "";
          });
        }
      } else {
        toolbar.container &&
        toolbar.container.querySelector(".ql-header .ql-picker-label")
          ? (toolbar.container.querySelector(
              ".ql-header .ql-picker-label"
            ).attributes["data-label"].value = this.$t("knowledge.body"))
          : "";
      }
    },
    // 发送评论
    sendComment() {
      if (this.quill.getContents().ops.length > 1) {
        const plainText = this.quill.getText(0);
        const content = JSON.stringify(this.quill.getContents().ops);
        const atList = [];
        const type = "document";
        const parentId = this.parentCommentId;
        this.quill.getContents().ops.forEach((item) => {
          if (item.attributes && item.attributes["my-span"]) {
            atList.push(item.attributes["my-span"].accountId);
          }
        });
        sendComments({
          projectId: this.get_pid(),
          documentId: this.checkedNode.documentId,
          data: {
            plainText,
            content,
            type,
            atList,
            parentId,
          },
        }).then((res) => {
          this.quill.setContents([]);
          this.info.showRepeat = false;
          this.$emit("getComments");
        });
      } else {
        this.$message({
          type: "warning",
          message: this.$t("knowledge.CommentNotEmpty"),
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-wrap {
  width: calc(100% - 60px);
  /*no*/
  margin-left: 60px;
  min-height: 200px;
  /*no*/
  position: relative;
  #editor-container-comment {
    min-height: 200px;
  }
  .at_position {
    position: absolute;
  }
}
.edit-button {
  margin-top: 20px;
  text-align: right;
}
</style>
<style lang="scss">
.ql-editor {
  min-height: 200px;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: #ebeef5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(207, 207, 207, 1);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(255, 255, 255, 1);
  }
}
.ql-font {
  margin-right: 10px; 
}
.ql-font,
.ql-size {
  border: 1px solid rgba(204, 204, 204, 1);
  border-radius: 2px;
}
.ql-editing {
  left: 20px !important;
}
.ql-video {
  width: 1027px;
  height: 624px;
}
</style>
