<template>
  <div :style="{ width: isCollapse ? '0' : '18%' }">
    <div v-show="!isCollapse" class="right-comment-wrap">
      <p 
        style="
          text-align: right; 
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
        " 
        @click="collpaseAside"
      >
        <span>{{ $t("knowledge.WordExtractCommentSection")
        }}({{ commentLength }})</span>
        <i
          class="iconfont icon-zhedie1"
          style="
            font-size: 24px;
            margin-right: 12px;
            color: rgba(128, 128, 128, 1);
            cursor: pointer
          "
        ></i>
      </p>
      <!-- <p style="margin-bottom: 10px; height: auto">
        {{ $t("knowledge.WordExtractCommentSection")
        }}<span>({{ commentLength }})</span>
      </p> -->
      <div class="commnet-warp">
        <div
          v-for="(item, index) in commentListFilter"
          :key="index"
          @click="positionDoc(item)"
        >
          <p style="margin-bottom: 0; display: flex; align-items: center">
            <personal-avatar
              :avatar="matchUserInfo(item.createdBy).avatar"
              :colorid="matchUserInfo(item.createdBy).accountId"
              :nickname="matchUserInfo(item.createdBy).nickname"
              :size="30"
              :tool-tip-disabled="true"
              style="
                margin-right: 5px;
                display: inline-block;
                vertical-align: middle;
              "
            ></personal-avatar>
            <span style="">{{ matchUserInfo(item.createdBy).nickname }}</span>
            <span style="display: flex; justify-content: right; flex-grow: 1">
              <el-select
                v-model="item.status"
                :class="{
                  status: true,
                  status_small: true,
                  status_todo: item.status == 'TODO',
                  status_progress: item.status == 'PROCESSING',
                  status_done: item.status == 'DONE',
                  status_abandon: item.status == 'ABANDON',
                }"
                placeholder=" "
                @change="changeWorkflow($event, item)"
              >
                <el-option
                  v-for="opt in questionOptions"
                  :key="opt.value"
                  :label="opt.label"
                  :value="opt.value"
                >
                </el-option>
              </el-select>
            </span>
          </p>
          <p
            class="comment_list_html"
            style="transform: scale(0.7); transform-origin: 0 0; margin: 0"
          >
            <span>{{
              new Date(item.createdAt).Format("yyyy-MM-dd hh:mm:ss")
            }}</span>
          </p>
          <div class="comment_list_html" v-html="item.html"></div>
          <p class="comment_list_opt">
            <i
              v-if="userAccountId === item.createdBy"
              class="iconfont icon-delete-red"
              style="font-size: 20px; margin-right: 20px; cursor: pointer"
              @click.stop="delFn(item)"
            />
            <i
              v-if="item.likedList.indexOf(userAccountId) !== -1"
              class="iconfont icon-k_zan"
              style="font-size: 20px; cursor: pointer; color: rgb(255, 195, 0)"
              @click.stop="zanFn(item)"
            />
            <i
              v-else
              class="iconfont icon-a-31dianzan"
              style="font-size: 20px; cursor: pointer"
              @click.stop="zanFn(item)"
            />
            <span style="margin-right: 20px"> {{ item.likedList.length }}</span>
            <i
              class="iconfont icon-comment"
              style="font-size: 18px; cursor: pointer"
              @click.stop="repeatFn(item)"
            />
          </p>
          <rightCommnetQuill
            v-if="item.showRepeat"
            :checked-node="checkedNode"
            :parent-comment-id="item.commentId"
            :info="item"
            :repeat="true"
            @getComments="getComments"
            @hideOthersReapet="hideOthersReapet"
          ></rightCommnetQuill>
          <rightTreeComment
            v-if="item.children.length"
            :checked-node="checkedNode"
            :tree-data="item.children"
            @getComments="getComments"
            @hideOthersReapet="hideOthersReapet"
          ></rightTreeComment>
        </div>
      </div>
      <div id="hide-wrap-right" class="hide-wrap-right"></div>
    </div>
    <div v-show="isCollapse" class="left-menu-hide" @click="collpaseAside">
      <div class="showCommentBtn">
        <i
          class="iconfont icon-comment_inline_g"
          style="font-size: 18px; color: rgba(128, 128, 128, 1);cursor: pointer"
        ></i>
      </div>
      
    </div>
  </div>
</template>

<script>
import PersonalAvatar from "@/components/personal";
import rightCommnetQuill from "./rightCommnetQuill.vue";
import rightTreeComment from "./rightTreeComment.vue";
import { mapGetters } from "vuex";
import {
  getComments,
  delComment,
  like,
  unlike,
  rightCommentChangeStatus,
} from "@/network/knowledge";
import Quill from "quill";
import { ulid } from "ulid";

export default {
  components: {
    PersonalAvatar,
    rightCommnetQuill,
    rightTreeComment,
  },
  props: {
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  watch: {
    checkedNode(newval) {
      this.getComments();
    },
  },
  data() {
    return {
      isCollapse: true,
      commentLength: 0,
      commentList: [],
      commentListFilter: [],
      lastcheckid: null,
      questionOptions: [
        {
          value: "TODO",
          label: "未开始",
        },
        {
          value: "PROCESSING",
          label: this.$t("canvas.status.st2"),
        },
        {
          value: "DONE",
          label: "已完成",
        },
        {
          value: "ABANDON",
          label: this.$t("knowledge.Abandoned"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userAccountId", "user_list"]),
  },
  mounted() {
    this.initQuill();
    this.getComments();
  },
  methods: {
    // 修改取词评论状态
    // 修改问题状态
    changeWorkflow(value, item) {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
        commentId: item.commentId,
        query: {
          status: value,
        },
      };
      rightCommentChangeStatus(params).then((res) => {});
    },
    // 定位documnet位置
    positionDoc(item) {
      this.lastcheckid && document.getElementById(this.lastcheckid)
        ? document
            .getElementById(this.lastcheckid)
            .classList.remove("high_light_doc")
        : "";
      setTimeout(() => {
        if (document.getElementById(item.lineId)) {
          document.getElementById(item.lineId).classList.add("high_light_doc");
          const rollDom = document.getElementById(item.lineId);
          const warp = document.getElementById("no-hidden-dialog");
          warp.scrollTop = rollDom.offsetTop;
          this.lastcheckid = item.lineId;
        }
      }, 0);
    },
    commentLengthAdd(arr) {
      arr.forEach((item) => {
        this.commentLength++;
        if (item.children) {
          this.commentLengthAdd(item.children);
        }
      });
    },
    // 过滤右侧
    filterRight(id) {
      if (this.isCollapse) {
        this.isCollapse = false;
      }
      this.commentLength = 0;
      if (id) {
        this.commentListFilter = this.commentList.filter((item) => {
          return item.lineId == id;
        });
        this.commentLengthAdd(this.commentListFilter);
      } else {
        this.commentListFilter = this.commentList;
        this.commentLength = this.originLength;
      }
    },
    initQuill() {
      this.quill = new Quill("#hide-wrap-right", {
        modules: {
          toolbar: false,
          history: {
            delay: 2000,
            maxStack: 500,
          },
        },
        theme: "snow",
      });
    },
    getComments() {
      if (this.checkedNode && this.checkedNode.documentId) {
        const params = {
          projectId: this.get_pid(),
          documentId: this.checkedNode.documentId,
          query: {
            type: "line",
          },
        };
        getComments(params).then((res) => {
          this.originLength = res.length;
          this.commentLength = res.length;
          this.commentList = this.buildTreeFn(res, "");
          this.commentListFilter = JSON.parse(JSON.stringify(this.commentList));
          this.$emit("commentinline", res);
        });
      } else {
        this.commentList = [];
        this.commentListFilter = [];
        this.originLength = 0;
        this.commentLength = 0;
        this.$emit("commentinline", []);
      }
    },
    buildTreeFn(data, id, createdBy) {
      const arr = [];
      data.forEach((item) => {
        if (item.parentId === id) {
          item.children = this.buildTreeFn(
            data,
            item.commentId,
            item.createdBy
          );
          const delta = JSON.parse(item.content);
          this.quill.setContents(delta);
          item.html = this.quill.root.innerHTML;
          item.showRepeat = false;
          createdBy ? (item.to = createdBy) : "";
          arr.push(item);
        }
      });
      return arr;
    },
    collpaseAside() {
      this.isCollapse = !this.isCollapse;
      if (!this.isCollapse) {
        this.initQuill();
      }
    },
    // 隐藏其他评论
    hideOthers(data) {
      data.forEach((item) => {
        item.showRepeat = false;
        item.children ? this.hideOthers(item.children) : "";
      });
    },
    hideOthersReapet() {
      this.hideOthers(this.commentListFilter);
    },
    repeatFn(item) {
      this.hideOthersReapet();
      item.showRepeat = true;
    },
    zanFn(item) {
      const params = {
        projectId: this.get_pid(),
        documentId: item.documentId,
        commentId: item.commentId,
      };
      if (item.likedList.indexOf(this.userAccountId) !== -1) {
        unlike(params).then((res) => {
          item.likedList.splice(item.likedList.indexOf(this.userAccountId), 1);
        });
      } else {
        like(params).then((res) => {
          item.likedList.push(this.userAccountId);
        });
      }
    },
    delFn(item) {
      const params = {
        projectId: this.get_pid(),
        documentId: item.documentId,
        commentId: item.commentId,
      };
      delComment(params).then((res) => {
        this.getComments();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.right-comment-wrap {
  width: 100%;
  height: 99%;
  text-align: left;
  padding-left: 20px;
  padding-top: 20px;
  box-shadow: 0px 2px 8px 2px rgba(186, 186, 186, 0.74);

  & > p {
    height: 30px;
  }

  .commnet-warp {
    height: calc(100% - 80px);
    overflow-y: scroll;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      width: 10px;
      height: 8px;
      background-color: #ebeef5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(207, 207, 207, 1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: rgba(255, 255, 255, 1);
    }

    & > div {
      width: 96%;
      margin-top: 20px;
      padding: 10px 20px;
      opacity: 1;
      border-radius: 6px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

      &:hover {
        cursor: pointer;
        box-shadow: 0px 4px 4px 0px rgba(230, 242, 252, 1);
      }

      & > p {
        color: rgba(128, 128, 128, 1);
      }

      .comment_list_opt {
        text-align: right;
      }
    }
  }

  & > p {
    // color: rgba(150, 140, 140, 1);
    font-size: 22px;
    margin: 0;

    &:first-of-type {
      font-size: 18px;
      font-weight: 700;
      margin: 0;
    }
  }
}

.left-menu-hide {
  height: 100%;
  display: flex;
  align-items: flex-start;
  text-align: center;

  &:hover {
    background-color: #f0f7ff;
    cursor: pointer;
  }
}

.status_todo {
  border: 1px solid rgba(204, 204, 204, 1);

  ::v-deep .el-input__inner {
    color: rgba(204, 204, 204, 1);
  }
}

.status_progress {
  border: 1px solid rgba(49, 96, 137, 1);

  ::v-deep .el-input__inner {
    color: rgba(49, 96, 137, 1);
  }
}

.status_done {
  border: 1px solid rgba(67, 207, 124, 1);

  ::v-deep .el-input__inner {
    color: rgba(67, 207, 124, 1);
  }
}

.status_abandon {
  border: 1px solid rgba(204, 204, 204, 1);
  background-color: rgba(204, 204, 204, 1);

  ::v-deep .el-input__inner {
    color: #fff;
  }
}

.status {
  // width: 135px;
  height: 32px;
  border-radius: 4px;
  // color: #fff;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;

  ::v-deep .el-select__caret {
    color: #000;
  }
}

.hide-wrap-right {
  display: none;
}

.comment_list_html {
  margin-left: calc(30px + 0.026rem) !important;
  word-break: break-all;
  /*no*/
}
.showCommentBtn{
    cursor: pointer;
    position: relative;
    left: -36px;
    top: 10px;
    display: flex;
    align-items: center;
    height: 35px;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 30px 0 0 30px;
    background-color: white;
    border: 1px solid rgba(229, 229, 229, 1);
    &:hover {
      background-color: #f0f7ff;
      cursor: pointer;
    }
}
</style>
<style>
@keyframes docLight {
  0% {
    background-color: #fff;
    box-shadow: 0px 1px 0px 0px rgba(9, 13, 20, 0);
  }
  50% {
    background-color: #f0f7ff;
    box-shadow: 0px 0px 7px 2px rgb(55 159 254 / 80%);
  }
  100% {
    background-color: #fff;
    box-shadow: 0px 1px 0px 0px rgba(9, 13, 20, 0);
  }
}

.high_light_doc {
  animation: docLight 1s 5;
  box-sizing: border-box;
}
</style>
