import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'

// 获取我的模板
export function getMyCreations(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeTemplates/myCreations?page=${params.page}&size=${params.size}`,
        method: 'get'
    })
}

// 新建模板
export function createTemplate(data) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeTemplates/myCreations`,
        data: data,
        method: 'post'
    })
}
// 获取收藏模板
export function getMyCollections(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeTemplates/myCollections?page=${params.page}&size=${params.size}`,
        method: 'get'
    })
}
// 获取团队模板
export function getTeamTemplate(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeTemplates/tenantCreation?page=${params.page}&size=${params.size}`,
        method: 'get'
    })
}
// 获取系统内置模板
export function getPreInstall(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeTemplates/preInstall?templateType=${params.templateType}&page=${params.page}&size=${params.size}`,
        method: 'get'
    })
}

// 应用模板
export function useTemplate(knowledgeTemplateId) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeTemplates/${knowledgeTemplateId}/apply`,
        method: 'post'
    })
}

// 收藏模板
export function collectTemplate(knowledgeTemplateId) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeTemplates/myCollections/${knowledgeTemplateId}`,
        method: 'post'
    })
}
// 取消收藏模板
export function cancelCollectTemplate(knowledgeTemplateId) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeTemplates/myCollections/${knowledgeTemplateId}`,
        method: 'delete'
    })
}

// 删除模板
export function deleteTemplate(knowledgeTemplateId) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeTemplates/${knowledgeTemplateId}`,
        method: 'delete'
    })
}

// 模糊搜索模板
export function fuzzyTemplate(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeTemplates/fuzzyQuery?page=${params.page}&size=${params.size}`,
        data: params.fuzzyKey,
        method: 'post'
    })
}

// 获取评论
export function getTemplateComment(knowledgeTemplateId) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeTemplates/${knowledgeTemplateId}/comments`,
        method: 'get'
    })
}

// 评论模板
export function createTemplateComment(data) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeTemplates/${data.knowledgeTemplateId}/comments`,
        data: data.content,
        method: 'post'
    })
}

// 获取内置模板的数目
export function getPreInstallNum() {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeTemplates/preInstall/counts`,
        method: 'get'
    })
}